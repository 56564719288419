import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { HelperService } from '../helper-service.service';

declare var $: any
var routeParams;
var captchaEvent;
@Component({
  selector: 'app-merchant',
  templateUrl: './merchant.component.html',
  styleUrls: ['./merchant.component.css']
})
export class MerchantComponent implements OnInit, OnDestroy {
  public merchantType: string;
  public searchData: Object = {};
  public validationErrors: Object = {};
  public merchantIcons: Array<Object> = [
    { name: 'fast', icon: '/assets/images/fast.png' },
    { name: 'accurate', icon: '/assets/images/accurate.png' },
    { name: 'comprehensive', icon: '/assets/images/comprehensive.png' }
  ]
  public socialIcons: Array<Object> = [
    { name: 'facebook', icon: 'fab fa-facebook-f' },
    { name: 'twitter', icon: 'fab fa-twitter' },
    { name: 'youtube', icon: 'fab fa-youtube' },
    { name: 'instagram', icon: 'fab fa-instagram' },
    { name: 'flickr', icon: 'fab fa-flickr' },
    { name: 'snapchat', icon: 'fab fa-snapchat-ghost' },
  ]
  public validationRules = {
    person_email: ['required|email']
  }
  constructor(private activeRoute: ActivatedRoute,
    private router: Router,
    private helperService: HelperService) {
    routeParams = activeRoute.params.subscribe(data => {
      if (data.type == 'social-media-search')
        this.merchantType = data.type
      else this.router.navigateByUrl('/landing/merchant/social-media-search')
    })
  }
  goTop() {
    $('html, body').animate({
      scrollTop: 0
    }, 400, () => {
      window.scrollTo(0, 0)
    })
  }
  ngOnInit() {
  }
  ngOnDestroy() {
    if (captchaEvent) captchaEvent.unsubscribe()
    if (routeParams) routeParams.unsubscribe()
  }
  sendInstantSearch(searchData: Object): void {
    this.validationErrors = {}
    var validated = true
    for (let key in this.validationRules) {
      this.validationErrors[key] = this.helperService.validatateRules(searchData[key], this.validationRules[key], searchData)
      if (this.validationErrors[key] && validated)
        validated = false
    }
    if (validated)
      this.router.navigate(['/landing/results/' + searchData['person_email']])
  }

}
