import { Component, OnInit, Input, Output, EventEmitter, NgZone, OnDestroy } from '@angular/core';
import { HttpService } from '../httpService';
import { HelperService } from '../helper-service.service';
import { CaptchaService } from '../captcha.service';

declare var $: any;
var captchaEvent;
@Component({
  selector: 'get-example',
  templateUrl: './get-example.component.html',
  styleUrls: ['./get-example.component.css']
})
export class GetExampleComponent implements OnInit, OnDestroy {
  public reportInfo: Object = {};
  public validationErrors: Object = {};
  public exampleTaken: boolean = false
  @Input() exampleType;
  @Output() reportTaken = new EventEmitter<string>();
  constructor(private httpService: HttpService,
    private helperService: HelperService,
    private captchaService: CaptchaService,
    private zone: NgZone) {
    if (this.captchaService.captchaReady)
      this.captchaService.getCaptchaCode()
    captchaEvent = this.captchaService.captchaEvent.subscribe(data => {
      this.zone.run(() => { this.reportInfo['captcha_token'] = data['captcha_token'] })
    })
  }
  getExampleReport(reportData: Object): void {
    reportData['sample_report_type'] = this.exampleType
    $("#mainContainer").addClass('fullLoader')
    this.httpService.postObjectToUrl(this.httpService.postApi + 'v1/reports/request/sample', reportData, 'json', '').then(data => {
      this.validationErrors = {}
      this.reportInfo['captcha_token'] = ''
      this.captchaService.getCaptchaCode()
      if (data['result'] == 'success') {
        this.reportInfo = {}
        this.exampleTaken = true
      }
      else if (data['result'] == 'error') {
        this.validationErrors = data['response']['errors']
        this.helperService.setToastr('error', 'toastr.success.sampleReport.title', 'toastr.error.fillAllData')
      }
      $("#mainContainer").removeClass('fullLoader')
    })
  }
  ngOnInit() {
  }
  ngOnDestroy() {
    if (captchaEvent) captchaEvent.unsubscribe()
  }

}
