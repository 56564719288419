import { Component, OnInit } from '@angular/core';


@Component({
  selector: 'app-request-call',
  templateUrl: './request-call.component.html',
  styleUrls: ['./request-call.component.css']
})
export class RequestCallComponent implements OnInit {
  constructor() { }

  ngOnInit() {
  }


}
