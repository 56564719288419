import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
let modalButtons = ['Yes', 'No', 'Cancel'];
@Component({
  selector: 'dialog-box',
  templateUrl: './dialog-box.component.html',
  styleUrls: ['./dialog-box.component.css']
})
export class DialogBoxComponent implements OnInit {

  constructor(private router: Router) { }

  ngOnInit() {
  }
  openDialog(header, body) {
    document.getElementById('dialogContainer').style.display = 'block';
    document.getElementById('dialogHeader').innerHTML = header
    document.getElementById('dialogBody').innerHTML = body
    var promise = new Promise((resolve, reject) => {
      for (let i = 0; i < modalButtons.length; i++) {
        document.getElementById('dialog' + modalButtons[i]).onclick = () => {
          document.getElementById('dialogContainer').style.display = 'none';
          routerChange.unsubscribe()
          resolve(modalButtons[i])
        }
      }
      let routerChange = this.router.events.subscribe(path => {
        routerChange.unsubscribe()
        document.getElementById('dialogContainer').style.display = 'none';
        resolve('Cancel')
      })
    })
    return promise
  }

}
