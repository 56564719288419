import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpService } from '../httpService';
import { PopUpService } from '../pop-up.service';

var routeParams;
var queryParams;
declare var $: any;
@Component({
  selector: 'app-view-instant',
  templateUrl: './view-instant.component.html',
  styleUrls: ['./view-instant.component.css']
})
export class ViewInstantComponent implements OnInit {
  public reportId: string;
  public reportData: Object = { available: {} };
  public instantPreview: Object = {}
  public profileKeys: Array<string> = [];
  public socialKeys: Object = {};
  public instantReportMessage: string;
  public socialIcons: Array<Object> = [
    { name: 'facebook', icon: 'fab fa-facebook-f' },
    { name: 'flicr', icon: 'fab fa-flickr' },
    { name: 'youtube', icon: 'fab fa-youtube' },
    { name: 'google', icon: 'fab fa-google-plus-g' },
    { name: 'skype', icon: 'fab fa-skype' },
    { name: 'instagram', icon: 'fab fa-instagram' },
    { name: 'linkedin', icon: 'fab fa-linkedin-in' },
    { name: 'dribbble', icon: 'fab fa-dribbble' },
    { name: 'twitter', icon: 'fab fa-twitter' },
    { name: 'pinterest', icon: 'fab fa-pinterest-p' },
  ]
  public reportInfos: Array<Object> = [
    { name: 'photos', icon: 'fas fa-camera' },
    { name: 'relationships', icon: 'fas fa-users' },
    { name: 'locations', icon: 'fas fa-map-marker-alt' },
    { name: 'employments', icon: 'fas fa-building' },
    { name: 'sexoffenders', icon: 'fas fa-ban' },
    { name: 'websites', icon: 'fas fa-globe-americas' },
    { name: 'phones', icon: 'fas fa-phone' },
    { name: 'compromised', icon: 'fas fa-question-circle' }
  ]
  public notFoundInfo: Array<string> = [
    "personal",
    "social",
    "contact",
    "dataCaptures",
    "location",
    "criminal",
    "employment",
    "connectionsMap"
  ]
  public reportTaken: boolean;
  constructor(private activeRoute: ActivatedRoute,
    private httpService: HttpService,
    private router: Router,
    private popUpService: PopUpService) {
    routeParams = activeRoute.params.subscribe(data => {
      this.reportId = data.reportId
      this.getInstantData(this.httpService.postApi + 'v1/reports/' + this.reportId)
    })
    queryParams = activeRoute.queryParams.subscribe(data => {
      if (data['instantSuccess']) {
        try {
          this.instantReportMessage = atob(data['instantSuccess'])
        }
        catch (e) { }
      }
      this.router.navigate(['/clientPanel/viewInstant/' + this.reportId], { queryParams: {}, skipLocationChange: true })
    })
  }
  scrollTo(name: string): void {
    document.getElementById(name).scrollIntoView()
  }
  scrollTop() {
    window.scrollTo(0, 0)
  }
  downloadReport() {
    $("#mainContainer").addClass('fullLoader')
    this.httpService.getObjectFromUrl(this.httpService.postApi + 'v1/instant/pdf/' + this.reportId, 'authorization').then(data => {
      if (data['result'] == 'success') {
        let a = document.createElement('a');
        a.setAttribute("href", data['response']['link']);
        a.setAttribute("download", '');
        a.classList.add('invisibleLink')
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        a.remove();
      }
      this.reportTaken = true
      $("#mainContainer").removeClass('fullLoader')
    })
  }
  setRerportData(data) {
    if (data['available']['paid']) {
      if (data['fullcontact'] && data['fullcontact']['details'] && data['fullcontact']['details']['profiles']) {
        for (let key in data['fullcontact']['details']['profiles']) {
          this.profileKeys.push(key)
          if (!this.socialKeys[key.toLowerCase()])
            this.socialKeys[key.toLowerCase()] = data['fullcontact']['details']['profiles'][key]['url']
        }
      }
      if (data['pipl'] && data['pipl']['urls']) {
        for (let i = 0; i < data['pipl']['urls'].length; i++) {
          if (data['pipl']['urls'][i]['@name']) {
            if (!this.socialKeys[data['pipl']['urls'][i]['@name'].toLowerCase()])
              this.socialKeys[data['pipl']['urls'][i]['@name'].toLowerCase()] = data['pipl']['urls'][i]['url']
          }
        }
      }
    }
    // else this.popUpService.initPopUp({ type: 'specialOffer', reportType: 'instant' })
    this.reportData = data
  }
  getInstantData(url: string): void {
    this.reportTaken = false
    $("#localSpiner").show()
    this.httpService.getObjectFromUrl(url, 'authorization').then(data => {
      if (data['result'] == 'success')
        this.setRerportData(data['response']['data'])
      this.reportTaken = true
      $("#localSpiner").hide()
    })
  }
  ngOnInit() {
  }
  ngOnDestroy() {
    if (routeParams) routeParams.unsubscribe()
    if (queryParams) queryParams.unsubscribe()
    this.popUpService.destroyPopUp()
  }

}
