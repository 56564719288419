import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-why-us',
  templateUrl: './why-us.component.html',
  styleUrls: ['./why-us.component.css']
})
export class WhyUsComponent implements OnInit {
  public checkData: Array<Object> = [
    { data: ['account', 'aliases', 'dateOfBirth', 'phoneNumbers'] },
    { data: ['emails', 'leakedPasswords', 'relations', 'addresses'] },
    { data: ['companies', 'criminalRecords', 'sexOffenders', 'ipAddresses'] }
  ]
  constructor() { }

  ngOnInit() {
  }

}
