import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { HelperService } from '../helper-service.service';
import { PopUpService } from '../pop-up.service';
import { UserService } from '../userService';

var sliderTimeout
var counterInterval
var testimonialTimeout;
declare var $: any;
declare var Hammer: any;
var sliderManager
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit, OnDestroy {
  public sliderNumber: number = 0;
  public activeTestimonial: number = 0;
  public coutnerData = { returning: 0, orders: 0, recommended: 0, targets: 0 }
  public homeSlider: Array<Object> = [
    //{ image: '/assets/images/Slide-1.jpg', id: 'slide1', href: "" },
    { image: '/assets/images/Slide-2.jpg', id: 'slide2', href: "" },
    //{ image: '/assets/images/Slide-3.jpg', id: 'slide3', href: "/landing/instant/report" }
  ]
  public testimonials: Array<{ comment: string, person: string }> = [
    { comment: "Thanks, nice work! I will use you in the future!", person: "Private Investigator, Colorado" },
    { comment: "I just received the report I ordered.  It is EXCELLENT and greatly appreciate the quick turn around.  It was my first report but after viewing it I am sure I will use your service again many times.", person: " Private Investigator, Florida" },
    { comment: "We were very pleased with the report we received, and will definitely be using you in the future!", person: "Private investigator, New Jersey" },
    { comment: "Great job!", person: "Private investigator, Texas" },
    { comment: "Valuable,accurate asset in conducting complicated investigations to identify and trace entities attempting to hide their true identity and conduct fraud and illicit activities", person: "Private Investigator, California" },
    { comment: "I did look it over and was impressed with the detail and amount of information.", person: "Private investigator, Indiana" },
    { comment: "Nice product!", person: "Private investigator, Florida" },
    { comment: "I am a retired law enforcement officer with over 30 years of experience and a licensed private investigator and find that SNF to be reliable and always available to assist", person: "Private Investigator, California" },
    { comment: "I am happy with your results and look forward to using your service soon.", person: "Private investigator, Florida" },
    { comment: "I would like to say that we are very happy with the service you provide.", person: "Private investigator, Dubai" },
    { comment: "The report was in fact very good indeed and helpful as well. ", person: "Private investigator, Portugal" },
    { comment: "Perfect.......great job......", person: "Private investigator, Texas" },
    { comment: "I know you guys are usually pretty good with my reports.", person: "Private investigator, New Mexico" },
    { comment: "Now that is how a report is supposed to look!  Very impressive!", person: "Private investigator, Florida" },
    { comment: "Your reports and assistance are very helpful and reasonably priced.  It's a good service.", person: "Private investigator, Florida" },
    { comment: "I am impressed.", person: "Private investigator, California" },
    { comment: "Very GOOD job I think can use your Service  perfect", person: "Private Detective Italy" },
    { comment: "Grazie per i report, saranno molto utile. Ordineremo anche altri.", person: "Private investigator, Italy" },
    { comment: "Thank you very much for your excellent professional work! The cooperation with your team has always been characterized by a trusting relationship and good communication", person: "Private investigator, Germany" },
    { comment: "Great report and thanks much.  We definitely will be doing many more of these same kind of reports.", person: "Private investigator, Washington DC" },
    { comment: "Thank you very much for trying to help me. You went above and beyond. I’ll continue to use your services and I will recommend it to everyone that I know. Thanks again!", person: "Private investigator, New York" },
    { comment: "I am extremely impressed with this report. I think your price is most reasonable for all the information provided. I will certainly bring your business to the attention of all my divorce clients. Thank-you.", person: "Private Investigator, Canada" },
    { comment: "I want to again thank you and your team for the excellent and timely work you provide. I truly appreciate the service you provide and your professional attitude.", person: "PI, Oklahoma" },
    { comment: "I would like to commend Social Network Finder on how thorough and useful your Deep Reports are in my business. You did an exceptionally good job on the last report, worthy of commendation.", person: "Private Investigator, Florida" },
    { comment: "I just want to say a huge Thank You, and that the report was amazing, and blew me away. I will be using you guys without a doubt, it was quick, excellent info, and a fair price.", person: "PI, Australia" },
    { comment: "Great job, when we stay under client pressure you stay with us. Thank you very much", person: "Global Private Investigator Switzerland" },
    { comment: "I run a private investigative agency and I am very impressed with your reports", person: "PI, Florida" },
    { comment: "I want to thank you for the excellent work and amazing turnaround time. I hope you will let the entire staff know how much I really appreciate working together. You make me look good.", person: "Private investigator, Oklahoma" },
    { comment: "You guys do great work. So happy to have found You.", person: "PI, Tennessee" },
    {
      comment: "Hi there, I ordered a report a few days ago. I would just like to say that the quality of your report is outstanding. I had already found a lot of details as all I had was a passport. But you went above and beyond! Great skills!!!!", person: "Private Investigator, France"
    }
  ]
  cinemaMode: boolean = false;


  public showExamplePanel: boolean;
  constructor(public helperService: HelperService, public popUpService: PopUpService, public userService: UserService, private router: Router) {
    if (userService.storageKey) {
      // $("#mainContainer").addClass('fullLoader')
      userService.getUser().then(data => {
        if (data['result'] == 'success')
          userService.setUser(data['response'])
        // $("#mainContainer").removeClass('fullLoader')
      })
    }
    // else this.router.navigateByUrl('/landing/home')
  }

  onCinema(): void {
    this.cinemaMode = !this.cinemaMode;
  }
  setExamplePanel(value: boolean): void {
    this.showExamplePanel = value
  }
  handleReportEvent(event) {
    if (event == 'success')
      this.setExamplePanel(false)
  }
  changeSlide(direction) {
    if (direction == 'next') {
      if (this.sliderNumber == this.homeSlider.length - 1) this.setSlider(0, true)
      else this.setSlider(this.sliderNumber + 1, true)
    }
    else if (direction == 'previous') {
      if (this.sliderNumber == 0) this.setSlider(this.homeSlider.length - 1, true)
      else this.setSlider(this.sliderNumber - 1, true)
    }
  }
  setSlider(index, animation) {
    var carousel = document.getElementById('homeCarousel')
    if (animation) {
      if (!$(carousel).hasClass('transition'))
        $(carousel).addClass('transition')
    }
    else if ($(carousel).hasClass('transition'))
      $(carousel).removeClass('transition')
    this.sliderNumber = index
    carousel.style.transform = 'translateX(-' + ((this.sliderNumber / this.homeSlider.length) * 100) + '%)'
    if (sliderTimeout) clearTimeout(sliderTimeout)
    sliderTimeout = setTimeout(() => { this.changeSlide('next') }, 8000)
  }
  setCarousel() {
    var carousel = document.getElementById('homeCarousel');
    carousel.style.width = this.homeSlider.length * 100 + '%'
    $(".homeCarouselElement").width('calc(100% / ' + this.homeSlider.length + ')')
    if (this.homeSlider.length > 1)
      this.setSlider(this.sliderNumber, false)
  }
  ngOnInit() {
    if (this.homeSlider.length > 1) {
      window.onresize = () => {
        this.setCarousel()
      }
      sliderManager = new Hammer.Manager(document.querySelector('#homeCarousel'));
      sliderManager.add(new Hammer.Pan({ threshold: 0, pointers: 0 }));
      sliderManager.get('pan').set({ direction: Hammer.DIRECTION_HORIZONTAL });
      sliderManager.on('pan', (e) => {
        let container = document.getElementById('homeCarousel');
        var percentage = 100 / this.homeSlider.length * e.deltaX / window.innerWidth;
        var transformPercentage = percentage - 100 / this.homeSlider.length * this.sliderNumber;
        if (container.classList.contains('transition'))
          container.classList.remove('transition')
        container.style.transform = 'translateX( ' + transformPercentage + '% )';
        if (e.isFinal) {
          let activeNumber = Math.round((Math.abs(transformPercentage)) / (100 / this.homeSlider.length))
          if (this.sliderNumber == activeNumber && Math.abs(e.deltaX / window.innerWidth) > 0.05) {
            if (e.deltaX > 0) {
              if (this.sliderNumber > 0)
                activeNumber = this.sliderNumber - 1
            }
            else if (e.deltaX < 0) {
              if (this.sliderNumber < this.homeSlider.length - 1)
                activeNumber = this.sliderNumber + 1
            }
          }
          if (Math.abs(e.velocityX) > 0 || activeNumber != this.sliderNumber) {
            if (transformPercentage > 0)
              this.setSlider(0, true)
            else if (Math.abs(transformPercentage) > (this.homeSlider.length - 1) * (100 / this.homeSlider.length))
              this.setSlider(this.homeSlider.length - 1, true)
            else
              this.setSlider(activeNumber, true)
          }
          else this.setSlider(this.sliderNumber, true)
        }
      });
    }
  }
  changeTestimonial(direction) {
    if (direction == 'next') {
      if (this.activeTestimonial == this.testimonials.length - 1) this.setTestimonatial(0)
      else this.setTestimonatial(this.activeTestimonial + 1)
    }
    else if (direction == 'previous') {
      if (this.activeTestimonial == 0) this.setTestimonatial(this.testimonials.length - 1)
      else this.setTestimonatial(this.activeTestimonial - 1)
    }
  }
  setTestimonatial(number) {
    this.activeTestimonial = number
    if (testimonialTimeout) clearTimeout(testimonialTimeout)
    testimonialTimeout = setTimeout(() => { this.changeTestimonial('next') }, 5000)
  }
  // setCounter(counterData, seconds) {
  //   let intervalValue = (seconds * 1000) / 100
  //   let counter = 0
  //   counterInterval = setInterval(() => {
  //     counter++
  //     for (let key in this.coutnerData) {
  //       if (counterData[key] < counter)
  //         this.coutnerData[key] = counterData[key]
  //       else this.coutnerData[key] = counter
  //     }
  //     if (counter == 100)
  //       clearInterval(counterInterval);
  //   }, intervalValue);
  // }
  ngAfterViewInit() {
    this.setCarousel()
    this.setTestimonatial(0)
    window.onscroll = () => {
      // let conterHolder = document.getElementById('counterHolder')
      // let topPosition = conterHolder.offsetTop
      // let bottomPosition = conterHolder.offsetTop + conterHolder.offsetHeight
      // if (window.pageYOffset < topPosition && (window.pageYOffset + window.innerHeight) > bottomPosition) {
      // this.setCounter({ returning: 98, orders: 85, recommended: 94, targets: 100 }, 3)
      setTimeout(() => {
        this.setExamplePanel(true)
      }, 5000)
      window.onscroll = undefined;
      // }
    }
  }

  ngOnDestroy() {
    this.popUpService.closePopUp()
    window.onscroll = undefined;
    sliderManager = undefined
    if (sliderTimeout) clearTimeout(sliderTimeout)
    if (testimonialTimeout) clearTimeout(testimonialTimeout)
    if (counterInterval) clearInterval(counterInterval);
    window.onresize = () => undefined
  }

}
