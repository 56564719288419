import { Component, OnInit, OnDestroy } from '@angular/core';
import { HttpService } from '../httpService';
import { ActivatedRoute, Router } from '@angular/router';
import { TableHeader } from '../models';
import { TrackService } from '../track.service';

declare var $: any;
let routeParams;
let queryParams;
@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit, OnDestroy {
  public noResultText: string = ''
  public statusFilter: string;
  public activeQuery: boolean;
  public pageObject: Object = {};
  public reports: Array<Object> = [];
  public searchReports: string;
  public pageSize: number = 10;
  public deepReportMessage: string = ''
  public successMessage = ''
  public auth: boolean;
  public pricePerId: any;
  public paidFor: any = ['whitelabel', 'court_records', 'criminal_records', 'plain_report'];
  public toppingList: any = [
    // { name: 'Criminal Records', value: 'add_criminal_records', mappedField: 'criminal_records' },
    // { name: 'Court Records', value: 'add_court_records', mappedField: 'court_records' },
    { name: 'Plain Report (no logo)', value: 'plain_report', mappedField: 'plain_report', disablefor: 'whitelabel' },
    { name: 'White label', value: 'whitelabel', mappedField: 'whitelabel', disablefor: 'plain_report' }
  ];
  public statuses: Array<string> = [
    'all',
    'requested',
    'processing',
    'completed'
  ]
  public reportHeaders: Array<TableHeader> = [
    { name: 'fullName' },
    { name: 'date' },
    { name: 'reportType' },
    { name: 'status' },
    { name: 'action' },
  ];
  constructor(private httpService: HttpService,
    private activeRoute: ActivatedRoute,
    private router: Router,
    private trackService: TrackService
  ) {
    routeParams = activeRoute.params.subscribe(data => {
      let pageParams = data.page.split("@")
      let statusValue = pageParams[0];
      let searchValue = '';
      if (data.search != 'none')
        searchValue = data.search
      window.scrollTo(0, 0)
      if (!this.pageObject['current_page'] || this.pageObject['current_page'] != pageParams[1] || this.statusFilter != statusValue || this.searchReports != searchValue) {
        this.statusFilter = statusValue
        this.searchReports = searchValue
        this.setGetUrl(pageParams[1])
      }
    })
    queryParams = activeRoute.queryParams.subscribe(data => {
      if (data['deepSuccess']) {
        this.trackService.pageChange('SuccessfulPaymentDeep')
        this.trackService.event('paymentDeep')
        try {
          this.deepReportMessage = atob(data['deepSuccess'])
        }
        catch (e) { }
      }

      if (data['successMessage']) {
        try {
          this.successMessage = atob(data['successMessage'])
        } catch (e) {

        }
      }
    })
  }
  setGetUrl(page): void {
    let appendUrl = 'v1/reports?page=' + page + '&pagesize=' + this.pageSize
    if (this.statusFilter != 'all')
      appendUrl += '&status=' + this.statusFilter
    if (this.searchReports)
      appendUrl += '&search=' + this.searchReports
    this.getReports(this.httpService.postApi + appendUrl)
  }
  
  noResult(): void {
    this.noResultText = 'There are no results found';
  }
  fillObject(data: Object): void {
    this.reports = data['data'];
    // console.log('REPORTI', this.reports);

    for (const report of this.reports) {
      report['additional_settings'] = []
      // if (report['add_court_records']) report['additional_settings'].push('add_court_records');
      // if (report['add_criminal_records']) report['additional_settings'].push('add_criminal_records');
      if (report['whitelabel']) report['additional_settings'].push('whitelabel');
      if (report['plain_report']) report['additional_settings'].push('plain_report');

      const dateCreatedString = report['created_at'];
      const dateCreated = new Date(dateCreatedString);

      const dateCreatedMilliseconds = new Date(dateCreated);
      const dateNowMilliseconds = new Date();
      let difference = dateCreatedMilliseconds.getTime() - dateNowMilliseconds.getTime();
      let totalDays = Math.ceil(difference / (1000 * 3600 * 24));

      if (Math.abs(totalDays) > 90) {
        report['isExpired'] = true;
        report['from_web'] = true;

      } else {
        report['isExpired'] = false;
        report['from_web'] = true;
      }

      // console.log('DATES in string', dateCreatedString)
      // console.log('DATES', dateCreated)
      // console.log('EXPIRED', report['isExpired'], 'Total Dates', totalDays)

      // this.httpService.getObjectFromUrl(this.httpService.postApi + `v1/report/price/${report['id']}`, 'authorization').then(data => {
      //   this.pricePerId = data['response'];
      // }
      // )
    }

    this.pageObject = {};
    for (let key in data['links']) {
      this.pageObject[key] = data['links'][key]
    }
    for (let key in data['meta']) {
      this.pageObject[key] = data['meta'][key]
    }
    let appendSearch = 'none'
    if (this.searchReports)
      appendSearch = this.searchReports
    this.router.navigateByUrl('/clientPanel/dashboard/' + this.statusFilter + '@' + this.pageObject['current_page'] + '/' + appendSearch)
  }
  getReports(url: string): void {
    this.activeQuery = true
    // $("#localSpiner").show()
    this.httpService.getObjectFromUrl(url, 'authorization').then(data => {
      if (data['result'] == 'success')
        this.fillObject(data['response'])
      this.activeQuery = false
      $("#localSpiner").hide()
    })
  }
  ngOnInit() {

  }

  // checkDisabled(report): any {
  //   if (report['paid_for'] && report['paid_for'] !== null) {
  //     for (let key in report['paid_for']) {
  //       if (report['paid_for'].includes(key)) {
  //         return true
  //       } else {
  //         return false
  //       }
  //     }
  //   }
  // }

  // log(reporto) {
  //   console.log('REPORT ', reporto)
  //   console.log(reporto['additional_settings'].includes('whitelabel'))
  // }

  onDropDownChange(report): void {
    if (report['additional_settings'] && report['additional_settings'].length > 0) {
      for (let topping of this.toppingList) {
        if (report['additional_settings'].includes(topping.value)) {
          report[topping.value] = 1;
        } else {
          report[topping.value] = 0;
        }
      }
    } else if (report['additional_settings'] && report['additional_settings'].length === 0) {
      for (let topping of this.toppingList) {
        report[topping.value] = 0;
      }
    }

    this.httpService.updateObjectToUrl(this.httpService.postApi + `v1/report/add/additional/${report.id}`, report, 'json', 'authorization')

  }
  ngOnDestroy() {
    if (routeParams) routeParams.unsubscribe()
    if (queryParams) queryParams.unsubscribe()
  }
}
