import { Component, OnInit, OnDestroy, NgZone } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { HelperService } from '../helper-service.service';
import { HttpService } from '../httpService';
import { environment } from '../../environments/environment';
import { PopUpService } from '../pop-up.service';

interface postObject {
  number: string,
  exp_month: string,
  exp_year: string,
  cvc: string
}

var routeParams;
var queryParams;
var listener;
declare var $: any;
declare var Stripe: any;
@Component({
  selector: 'app-checkout',
  templateUrl: './checkout.component.html',
  styleUrls: ['./checkout.component.css']
})
export class CheckoutComponent implements OnInit, OnDestroy {
  public checkOutData: Object = {};
  public validationErrors: Object = {};
  public validationPayment: Object = {};
  public cardData: Object = {};
  public reportData: Object = { available: {} };
  public reportType: string = '';
  public terms: boolean = false;
  public payPalData: Object = {};
  public stripeData: Object = {};
  // public reportPrices: Object = { instant: 4.95, deep: 29.95, deepLabeled: 44.95, whitelabel: 15.00, add_criminal_records: 4.95 }
  public presentPrice;
  public couponState: Object = {};
  public reportId: any;
  public pricePerId: any;
  public onlyAdditional: boolean = false;
  public showCouponIcon: boolean = false;
  public priceAfterCoupon: any = 0;

  constructor(private activeRoute: ActivatedRoute,
    public helperService: HelperService,
    private zone: NgZone,
    private httpService: HttpService,
    private router: Router,
    private popUpService: PopUpService) {

    Stripe.setPublishableKey(environment.stripeToken);
    routeParams = activeRoute.params.subscribe(data => {
      this.reportId = data['reportId'];
      this.reportType = data.type;
      // console.log('TYPE',this.reportType )
      this.checkOutData['report'] = parseInt(data.reportId)
      // if (this.reportType == 'whitelabel') this.checkOutData['whitelabel'] = true
      // if (this.reportType == 'plain_report') this.checkOutData['plain_report'] = true
      // if (this.reportType == 'add_court_records') this.checkOutData['add_court_records'] = true
      // if (this.reportType == 'add_criminal_records') this.checkOutData['add_criminal_records'] = true

      // this.presentPrice = this.reportPrices[this.reportType].toFixed(2)
      if (data.payPalMessage != 'none') {
        if (data.payPalMessage.includes('fail@')) {
          this.payPalData['type'] = 'error'
          this.payPalData['message'] = atob(data.payPalMessage.split("fail@").pop())
        }
        else if (data.payPalMessage.includes('success@')) {
          this.payPalData['type'] = 'success'
          this.payPalData['message'] = atob(data.payPalMessage.split("success@").pop())
        }
      }
      this.getReport(this.httpService.postApi + 'v1/reports/' + this.checkOutData['report'])
    })
  }

  ngOnInit() {
    this.httpService.getObjectFromUrl(this.httpService.postApi + `v1/report/price/${this.reportId}`, 'authorization').then(data => {
      this.pricePerId = data['response'];
      // price check for only additional report
      if (this.pricePerId == 15) {
        this.onlyAdditional = true
      } else {
        this.onlyAdditional = false
      }
    }
    )

  }

  getReport(url: string): void {
    $("#localSpiner").show()
    this.httpService.getObjectFromUrl(url, 'authorization').then(data => {
      if (data['result'] == 'success') {
        this.reportData = data['response']['data']
        if (this.reportData['available']['coupon']) {
          this.checkOutData['coupon_code'] = this.reportData['available']['coupon']
          this.discountPrice({ discount_value: this.reportData['available']['coupon_discount'], discount_type: this.reportData['available']['coupon_type'] })
        }
        // if (this.reportType == 'instant')
        //   this.popUpService.initPopUp({ type: 'specialOffer', reportType: 'instant' })
      }
      $("#localSpiner").hide()
    })
  }
  discountPrice(discountData: Object): void {
    if (discountData['discount_type'] == 'fixed') {
      this.priceAfterCoupon = (this.pricePerId - discountData['discount_value']).toFixed(2)
    }
    else if (discountData['discount_type'] == 'percentage') {
      this.priceAfterCoupon = (this.pricePerId - (this.pricePerId * (discountData['discount_value'] / 100))).toFixed(2)
    }
    if (this.priceAfterCoupon < 0)
      this.priceAfterCoupon = 0.00
  }
  getCoupon(coupon: string): void {
    if (coupon) {
      $("#localSpiner").show()
      this.httpService.getObjectFromUrl(this.httpService.postApi + 'v1/coupon/' + coupon + '/' + this.checkOutData['report'], 'authorization').then(data => {
        // this.presentPrice = this.reportPrices[this.reportType]
        this.priceAfterCoupon;
        this.couponState = {};
        if (data['result'] == 'success') {
          this.showCouponIcon = true;
          this.couponState['status'] = 'success'
          this.discountPrice(data['response'])
        }
        else if (data['result'] == 'error') {
          this.showCouponIcon = false;
          this.couponState['status'] = 'error'
          if (data['response']['message'])
            this.couponState['message'] = data['response']['message']
        }
        $("#localSpiner").hide()
      })
    }
    else {
      this.couponState = {};
      this.priceAfterCoupon;
      // this.presentPrice = this.reportPrices[this.reportType]
    }
  }
  checkCard(cardObject, checkOutData) {
    if (!this.terms)
      this.validationPayment['terms'] = true
    else {
      $("#mainContainer").addClass('fullLoader')
      let postObject: postObject = {
        number: cardObject.number || '',
        exp_month: cardObject.exp_month || '',
        exp_year: cardObject.exp_year || '',
        cvc: cardObject.cvc || ''
      }
      if (postObject.number) postObject.number = postObject.number.replace(/\s/g, "")
      Stripe.card.createToken(postObject, (status: number, response: any) => {
        this.zone.run(() => {
          this.validationPayment = {};
          this.stripeData['message'] = ''
          if (status == 200) {
            checkOutData['stripeToken'] = response.id
            checkOutData['payment_method'] = 'stripe'
            this.sendPayment(checkOutData)
          }
          else {
            checkOutData['stripeToken'] = undefined
            this.stripeData['type'] = 'error'
            if (response.error) {
              for (let key in postObject) {
                if (!postObject[key]) this.validationPayment[key] = [this.helperService.translateKey('requiredField')]
              }
              if (response.error.param)
                this.validationPayment[response.error.param] = [response.error.message]
              if (response.error.message) this.stripeData['message'] = response.error.message
            }
            $("#mainContainer").removeClass('fullLoader')
          }
        })
      })
    }
  }
  sendPayment(paymentData: Object): void {
    this.httpService.postObjectToUrl(this.httpService.postApi + 'v1/payment/checkout', paymentData, 'json', 'authorization').then(data => {
      this.validationErrors = {};
      if (data['result'] == 'success') {
        if (this.reportType == 'instant')
          this.router.navigate(['/clientPanel/viewInstant/' + this.checkOutData['report']], { queryParams: { instantSuccess: btoa(this.helperService.translateKey('toastr.success.' + this.reportType + '.body')) } })
        else
          // this.router.navigate(['/clientPanel/dashboard/all@1/none'], { queryParams: { deepSuccess: btoa(this.helperService.translateKey('toastr.success.' + this.reportType + '.body')) } })
          this.router.navigate(['/clientPanel/thankyou'], { queryParams: { deepSuccess: btoa(this.helperService.translateKey('toastr.success.' + this.reportType + '.body')) } })
      }
      else if (data['result'] == 'error') {
        this.stripeData['type'] = "error"
        this.stripeData['message'] = data['response']['message']
      }
      $("#mainContainer").removeClass('fullLoader')
    })
  }
  setPayPal(checkOutData) {
    checkOutData['payment_method'] = 'paypal'
    $("#mainContainer").addClass('fullLoader')
    this.httpService.postObjectToUrl(this.httpService.postApi + 'v1/payment/checkout', checkOutData, 'json', 'authorization').then(data => {
      if (data['result'] == 'success') {
        if (data['response']['paypal'])
          window.location.href = data['response']['paypal'];
        else if (data['response']['message']) {
          if (this.reportType == 'instant')
            this.router.navigate(['/clientPanel/viewInstant/' + this.checkOutData['report']], { queryParams: { instantSuccess: btoa(data['response']['message']) } })
          else
            // this.router.navigate(['/clientPanel/dashboard/all@1/none'], { queryParams: { deepSuccess: btoa(data['response']['message']) } })
            this.router.navigate(['/clientPanel/thankyou/'], { queryParams: { deepSuccess: btoa(this.helperService.translateKey('toastr.success.' + this.reportType + '.body')) } })
        }
      }
      else if (data['result'] == 'error') {
        this.stripeData['type'] = "error"
        this.stripeData['message'] = data['response']['message']
      }
      $("#mainContainer").removeClass('fullLoader')
    })
  }

  ngOnDestroy() {
    if (routeParams) routeParams.unsubscribe()
    this.popUpService.destroyPopUp()
  }

}
