// This file can be replaced during build by using the `fileReplacements` array.
// `ng build ---prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  apiUrl: 'https://api.socialnetworkfinder.com/',
  stripeToken: 'pk_live_WuEq4h8go6sjSlmltNUVp3WC'
};

// export const environment = {
//   production: false,
//   apiUrl: 'http://stage-api.socialnetworkfinder.com/',
//   stripeToken: 'pk_test_fzY3scbfg4oF7NScODwQ2e8a'
// };

/*
 * In development mode, for easier debugging, you can ignore zone related error
 * stack frames such as `zone.run`/`zoneDelegate.invokeTask` by importing the
 * below file. Don't forget to comment it out in production mode
 * because it will have a performance impact when errors are thrown
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.

//stageUrl:'http://snf-env.zpm3zz82wf.us-east-2.elasticbeanstalk.com/'

//apiUrl:'https://api.socialnetworkfinder.com/'

//stripeTest:'pk_test_fzY3scbfg4oF7NScODwQ2e8a'

//stripeLive:'pk_live_WuEq4h8go6sjSlmltNUVp3WC'
