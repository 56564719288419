import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

var routeParams;
var languageChange;
@Component({
  selector: 'app-legal-data',
  templateUrl: './legal-data.component.html',
  styleUrls: ['./legal-data.component.css']
})
export class LegalDataComponent implements OnInit, OnDestroy {
  public lastUpdated: string = "March 8, 2018";
  public legalType: string = ''
  public currentLanguage: string = ''
  constructor(private activeRoute: ActivatedRoute,
    private translate: TranslateService) {
    this.currentLanguage = this.translate.currentLang
    routeParams = activeRoute.params.subscribe(data => {
      window.scrollTo(0, 0)
      if (data.type == 'privacyPolicy' || data.type == 'cookies' || data.type == 'termsOfUse')
        this.legalType = data.type
      else this.legalType = 'privacyPolicy'
    })
    languageChange = translate.onLangChange.subscribe(lang => {
      this.currentLanguage = lang.lang;
    })
  }

  ngOnInit() {
  }
  ngOnDestroy() {
    if (routeParams) routeParams.unsubscribe()
    if (languageChange) languageChange.unsubscribe()
  }

}
