import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LandingComponent } from './landing/landing.component';
import { HomeComponent } from './home/home.component';
import { ClientPanelComponent } from './client-panel/client-panel.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { InstantReportComponent } from './instant-report/instant-report.component';
import { AboutComponent } from './about/about.component';
import { DeepReportComponent } from './deep-report/deep-report.component';
import { CompareProductsComponent } from './compare-products/compare-products.component';
import { RequestCallComponent } from './request-call/request-call.component';
import { ContactComponent } from './contact/contact.component';
import { WhyUsComponent } from './why-us/why-us.component';
import { LogInComponent } from './log-in/log-in.component';
import { SignUpComponent } from './sign-up/sign-up.component';
import { FaqComponent } from './faq/faq.component';
import { LegalDataComponent } from './legal-data/legal-data.component';
import { SettingsComponent } from './settings/settings.component';
import { InvoicesComponent } from './invoices/invoices.component';
import { InstantPreviewComponent } from './instant-preview/instant-preview.component';
import { CheckoutComponent } from './checkout/checkout.component';
import { BuyInstantComponent } from './buy-instant/buy-instant.component';
import { ViewInstantComponent } from './view-instant/view-instant.component';
import { OrderDeepComponent } from './order-deep/order-deep.component';
import { BuyDeepComponent } from './buy-deep/buy-deep.component';
import { TutorialsComponent } from './tutorials/tutorials.component';
import { GetInstantComponent } from './get-instant/get-instant.component';
import { MerchantComponent } from './merchant/merchant.component';
import { PublicResultsComponent } from './public-results/public-results.component';
import { LoggedResultsComponent } from './logged-results/logged-results.component';
import { PublicSignUpComponent } from './public-sign-up/public-sign-up.component';
import { ProductPriceComponent } from './product-price/product-price.component';
import { ThankYouComponent } from './shared/thank-you/thank-you.component';
import { EditDeepReportComponent } from './edit-deep-report/edit-deep-report.component';

const routes: Routes = [
  { path: '', redirectTo: '/landing/home', pathMatch: 'full' },
  {
    path: 'landing', component: LandingComponent, data: { "name": "landing" },
    children: [
      { path: '', redirectTo: 'home', pathMatch: 'full' },
      { path: 'home', component: HomeComponent, data: { "main": "home", "sub": "", "auth": "all" } },
      { path: 'about', component: AboutComponent, data: { "main": "aboutUs", "sub": "", "auth": "all" } },
      { path: 'productPricing', component: ProductPriceComponent, data: { "main": "productPricing", "sub": "", "auth": "all" } },
      //{ path: 'instant/:type', component: InstantReportComponent, data: { "main": "products", "sub": "instantReport", "auth": "all" } },
      //{ path: 'deepReport', component: DeepReportComponent, data: { "main": "products", "sub": "deepReport", "auth": "all" } },
      //{ path: 'compareProducts', component: CompareProductsComponent, data: { "main": "products", "sub": "compare", "auth": "all" } },
      { path: 'requestCall', component: RequestCallComponent, data: { "main": "requstCall", "sub": "", "auth": "all" } },
      { path: 'contact', component: ContactComponent, data: { "main": "contact", "sub": "", "auth": "all" } },
      { path: 'whyUs', component: WhyUsComponent, data: { "main": "whyUs", "sub": "", "auth": "all" } },
      //{ path: 'results/:email', component: PublicResultsComponent, data: { "main": "results", "sub": "", "auth": "all" } },
      { path: 'orderDeep', component: OrderDeepComponent, data: { "main": "orderDeep", "sub": "", "auth": "all" } },
      //{ path: 'getInstant', component: GetInstantComponent, data: { "main": "getInstant", "sub": "", "auth": "all" } },
      //{ path: 'tutorials', component: TutorialsComponent, data: { "main": "tutorials", "sub": "", "auth": "all" } },
      //{ path: 'merchant/:type', component: MerchantComponent, data: { "main": "merchant", "sub": "", "auth": "all" } },
      { path: 'signUp/:type', component: PublicSignUpComponent, data: { "main": "signUp", "sub": "", "auth": "all" } },
      { path: 'faq', component: FaqComponent, data: { "main": "faq", "sub": "", "auth": "all" } },
      { path: 'legal/:type', component: LegalDataComponent, data: { "main": "legal", "sub": "", "auth": "all" } },
      //{ path: 'instantPreview/:reportId', component: InstantPreviewComponent, data: { "main": "instantPreview", "sub": "", "auth": "all" } },
      { path: 'logIn/:type', component: LogInComponent, data: { "main": "logIn", "sub": "", "auth": "all" } },
      { path: '**', redirectTo: 'home' },
    ]
  },
  {
    path: 'clientPanel', component: ClientPanelComponent, data: { "name": "clientPanel" },
    children: [
      { path: '', redirectTo: 'dashboard/all@1/none', pathMatch: 'full' },
      { path: 'dashboard/:page/:search', component: DashboardComponent, data: { "main": "dashboard", "sub": "", "auth": "logged" } },
      { path: 'invoices/:page', component: InvoicesComponent, data: { "main": "invoices", "sub": "", "auth": "logged" } },
      { path: 'settings', component: SettingsComponent, data: { "main": "settings", "sub": "", "auth": "logged" } },
      { path: 'thankyou', component: ThankYouComponent },
      { path: 'edit-deep-report/:id', component: EditDeepReportComponent },
      //{ path: 'results/:email', component: LoggedResultsComponent, data: { "main": "results", "sub": "", "auth": "all" } },
      //{ path: 'buyInstant', component: BuyInstantComponent, data: { "main": "buyInstant", "sub": "", "auth": "logged" } },
      { path: 'buyDeep/:reportId', component: BuyDeepComponent, data: { "main": "buyDeep", "sub": "", "auth": "logged" } },
      { path: 'viewInstant/:reportId', component: ViewInstantComponent, data: { "main": "viewInstant", "sub": "", "auth": "logged" } },
      { path: 'checkout/:type/:reportId/:payPalMessage', component: CheckoutComponent, data: { "main": "checkout", "sub": "", "auth": "logged" } },
      { path: '**', redirectTo: 'dashboard/all@1/none' },
    ]
  },
  { path: '**', redirectTo: '/landing/home' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
