import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UserService } from '../userService';
import { HelperService } from '../helper-service.service';
import { TrackService } from '../track.service';

@Component({
  selector: 'app-landing',
  templateUrl: './landing.component.html',
  styleUrls: ['./landing.component.css']
})
export class LandingComponent implements OnInit, OnDestroy {
  public activeMenu: string;
  public activeSub: string;
  public openedMenu: string = '';
  public showResponsiveMenu: boolean = false;
  public disableResponsive: boolean;
  public currentYear: number;
  public showCallForm: boolean;
  public snfPhone = { plain: 8008080862, formated: '800-808-0862' };
  public showTalkImage: boolean;
  public clickDisabled: boolean;
  constructor(private activeRoute: ActivatedRoute,
    public userService: UserService,
    public helperService: HelperService,
    private router: Router,
    private trackService: TrackService) {
    this.currentYear = new Date().getFullYear();
  }
  openSub(subName: string): void {
    this.openedMenu = subName
  }
  setDisable(value: boolean): void {
    this.clickDisabled = value
  }
  setCallPanel(value: boolean): void {
    this.showCallForm = value
    if (this.showCallForm)
      document.getElementsByTagName("body")[0].style.overflowY = 'hidden'
    else document.getElementsByTagName("body")[0].style.overflowY = 'auto';
  }
  ngOnDestroy() {
    if (document.getElementsByTagName("body")[0].style.overflowY == 'hidden')
      document.getElementsByTagName("body")[0].style.overflowY = 'auto';
  }
  handleRequestEvent(event) {
    if (event == 'success')
      this.setCallPanel(false)
  }
  routerChange() {
    window.scrollTo(0, 0)
    this.activeMenu = this.activeRoute.firstChild.data['_value']['main']
    if (this.activeRoute.firstChild.data['_value']['sub'])
      this.activeSub = this.activeRoute.firstChild.data['_value']['sub']
    else this.activeSub = ''
    this.openedMenu = ''
    if (this.showResponsiveMenu) this.showResponsiveMenu = false
    if (this.activeMenu !== 'contact' && this.activeMenu != 'requstCall')
      this.showTalkImage = true
    else this.showTalkImage = false
    if (this.showCallForm)
      this.setCallPanel(false)
    this.trackService.pageChange(this.router.url)
  }
  checkSubRoute(routeName: string): void {
    if (this.activeSub == routeName)
      window.scrollTo(0, 0)
  }
  toggleResponsiveMenu() {
    this.showResponsiveMenu = !this.showResponsiveMenu
  }
  setResponsiveClick(value) {
    this.disableResponsive = value
  }
  ngOnInit() {
  }

}
