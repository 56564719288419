import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

var routeParams;
@Component({
  selector: 'app-logged-results',
  templateUrl: './logged-results.component.html',
  styleUrls: ['./logged-results.component.css']
})
export class LoggedResultsComponent implements OnInit {
  public searchDetails: Object = { place: 'logged' }
  constructor(private activeRoute: ActivatedRoute) {
    routeParams = activeRoute.params.subscribe(data => {
      if (data['email']) this.searchDetails['email'] = data['email']
    })
  }

  ngOnInit() {
  }
  ngOnDestroy() {
    if (routeParams) routeParams.unsubscribe()
  }

}
