import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-thank-you',
  templateUrl: './thank-you.component.html',
  styleUrls: ['./thank-you.component.css']
})
export class ThankYouComponent implements OnInit {
  successMessage = ''

  constructor(private router: Router, private activatedRoute: ActivatedRoute) { }

  ngOnInit() {
    this.activatedRoute.queryParams.subscribe(data=>{      
      if (data.deepSuccess)this.successMessage = data.deepSuccess;     
      
    })

    

    setTimeout(() => {
      let queryParams  = {}
      if (this.successMessage) queryParams['successMessage'] = this.successMessage
      this.router.navigate(['/clientPanel/dashboard/all@1/none'], {queryParams})
    }, 3000)
  }

}
