import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-compare-products',
  templateUrl: './compare-products.component.html',
  styleUrls: ['./compare-products.component.css']
})
export class CompareProductsComponent implements OnInit {
  public productsList: Array<Object> = [
    {
      name: 'instant', price: '$ 4.<small>95</small>', featuresLink: '/landing/instant/report', orderLink: '/landing/getInstant', features: [
        'mailSearch',
        'automaticallyGenerated',
        'instantAccess',
        'providesInformation',
        'pdfOption'
      ]
    },
    {
      name: 'deep', price: '$ 29.<small>95</small>', featuresLink: '/landing/deepReport', orderLink: '/landing/orderDeep', features: [
        'fullName',
        'manuallyPrepared',
        'detailedReport',
        'doubleChecked',
        'fullyBranded',
        'reportStatus',
        'spcecificInstructions',
        '72Hours'
      ]
    },
    {
      name: 'corporate', orderLink: '/landing/contact', features: [
        'flexiblePlan',
        'contactUs',
      ]
    }
  ]
  constructor() { }
  ngOnInit() {
  }

}
