
import { Injectable, EventEmitter } from '@angular/core';
import { HelperService } from './helper-service.service';
import { UserService } from './userService';
import { httpPromiceData } from './models';
import { environment } from '../environments/environment';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class HttpService {
  public activePath: string;
  public activelanguage: string = '';
  public apiUrl: string = environment.apiUrl;
  public postApi: string = ''

  constructor(private http: HttpClient,
    public helperService: HelperService,
    public userService: UserService) {
  }
  flattenObject(ob: Object) {
    var toReturn = {};
    for (var i in ob) {
      if (!ob.hasOwnProperty(i)) continue;
      if ((typeof ob[i]) == 'object' && !(ob[i] instanceof File) && !(ob[i] instanceof Blob) && !(ob[i] instanceof Date)) {
        var flatObject = this.flattenObject(ob[i]);
        for (var x in flatObject) {
          if (!flatObject.hasOwnProperty(x)) continue;

          toReturn[i + '.' + x] = flatObject[x];
        }
      } else {
        toReturn[i] = ob[i];
      }
    }
    return toReturn;
  };
  transformObjectToFormData(newObject: Object) {
    var formData = new FormData()
    for (let key in newObject) {
      var array = key.split('.')
      var name = ''
      for (var i = 0; i < array.length; i++) {
        if (name == '') {
          name = array[0]
        } else name = name + '[' + array[i] + ']'
      }
      if ((newObject[key] instanceof File) || (newObject[key] instanceof Blob)) {
        formData.append(name, newObject[key], newObject[key].name);
      }
      else
        formData.append(name, newObject[key])
    }
    return formData
  }
  getSubscribtion(url: string, auth: string): Observable<any> {
    var headers;
    if (auth == 'authorization') {
      try {
        headers = new HttpHeaders().set('Authorization', 'Bearer ' + atob(localStorage[this.userService.userToken]))
      }
      catch (e) { }
    }
    else headers = new HttpHeaders()
    return this.http.get(url, { headers: headers })
  }
  getObjectFromUrl(url: string, auth: string) {
    var promise = new Promise((resolve, reject) => {
      let responceData: httpPromiceData = { 'response': {}, 'result': '' }
      var headers;
      if (auth == 'authorization') {
        try {
          headers = new HttpHeaders().set('Authorization', 'Bearer ' + atob(localStorage[this.userService.userToken]))
        }
        catch (e) { }
      }
      else headers = new HttpHeaders()
      this.http.get(url, { headers: headers })
        .subscribe(
          (data) => {
            responceData = { 'response': data, 'result': 'success' }
            resolve(responceData)
          },
          (err) => {

            if (err.status == 401 || err.status == 403) {
              this.helperService.setToastr('error', 'toastr.error.notAuth.title', 'toastr.error.notAuth.body')
              this.userService.logOut()
            }
            if (err.status == 500) this.helperService.serverError()
            if (err.status == 400 || err.status == 422 || err.status == 412 || err.status == 406 || err.status == 404)
              responceData = { 'response': err['error'], 'result': 'error' }
            resolve(responceData)
          })
    })
    return promise
  }

  postObjectToUrl(url: string, object: Object, type: string, auth: string) {
    if (window !== window.parent) {
      object['from_mobile'] = '1'
      object['from_web'] = 'false'
    }
    var promise = new Promise((resolve, reject) => {
      let responceData: httpPromiceData = { 'response': {}, 'result': '' }
      var headers;
      if (auth == 'authorization') {
        try {
          headers = new HttpHeaders().set('Authorization', 'Bearer ' + atob(localStorage[this.userService.userToken]))
        }
        catch (e) { }
      }
      else headers = new HttpHeaders()
      var postObject
      if (type == 'formData') postObject = this.transformObjectToFormData(this.flattenObject(object))
      else postObject = object
      this.http.post(url, postObject, { headers: headers })
        .subscribe(
          (data) => {
            let responceData: httpPromiceData = { 'response': data, 'result': 'success' }
            resolve(responceData)
          },
          (err) => {
            if (err.status == 401 || err.status == 403) {
              this.helperService.setToastr('error', 'toastr.error.notAuth.title', 'toastr.error.notAuth.body')
              this.userService.logOut()
            }
            if (err.status == 500) this.helperService.serverError()
            if (err.status == 400 || err.status == 422 || err.status == 412 || err.status == 406 || err.status == 404)
              responceData = { 'response': err['error'], 'result': 'error' }
            resolve(responceData)
          })
    })
    return promise
  }

  updateObjectToUrl(url: string, object: Object, type: string, auth: string) {
    if (window !== window.parent) {
      object['from_mobile'] = '1'
      object['from_web'] = 'false'
    }
    var promise = new Promise((resolve, reject) => {
      let responceData: httpPromiceData = { 'response': {}, 'result': '' }
      var headers;
      if (auth == 'authorization') {
        try {
          headers = new HttpHeaders().set('Authorization', 'Bearer ' + atob(localStorage[this.userService.userToken]))
        }
        catch (e) { }
      }
      else headers = new HttpHeaders()
      var postObject
      if (type == 'formData') postObject = this.transformObjectToFormData(this.flattenObject(object))
      else postObject = object
      this.http.patch(url, postObject, { headers: headers })
        .subscribe(
          (data) => {
            let responceData: httpPromiceData = { 'response': data, 'result': 'success' }
            resolve(responceData)
          },
          (err) => {
            if (err.status == 401 || err.status == 403) {
              this.helperService.setToastr('error', 'toastr.error.notAuth.title', 'toastr.error.notAuth.body')
              this.userService.logOut()
            }
            if (err.status == 500) this.helperService.serverError()
            if (err.status == 400 || err.status == 422 || err.status == 412 || err.status == 406 || err.status == 404)
              responceData = { 'response': err['error'], 'result': 'error' }
            resolve(responceData)
          })
    })
    return promise
  }

  deleteObjectToUrl(url: string, auth: string) {
    var promise = new Promise((resolve, reject) => {
      let responceData: httpPromiceData = { 'response': {}, 'result': '' }
      var headers;
      if (auth == 'authorization') {
        try {
          headers = new HttpHeaders().set('Authorization', 'Bearer ' + atob(localStorage[this.userService.userToken]))
        }
        catch (e) { }
      }
      else headers = new HttpHeaders()
      this.http.delete(url, { headers: headers })
        .subscribe(
          (data) => {
            let responceData: httpPromiceData = { 'response': data, 'result': 'success' }
            resolve(responceData)
          },
          (err) => {
            if (err.status == 401 || err.status == 403) {
              this.helperService.setToastr('error', 'toastr.error.notAuth.title', 'toastr.error.notAuth.body')
              this.userService.logOut()
            }
            if (err.status == 500) this.helperService.serverError()
            if (err.status == 400 || err.status == 422 || err.status == 412 || err.status == 406 || err.status == 404)
              responceData = { 'response': err['error'], 'result': 'error' }
            resolve(responceData)
          })
    })
    return promise
  }
  setPostApi(lang: string) {
    // this.postApi = this.apiUrl + lang + '/'
    this.activelanguage = lang
    this.postApi = this.apiUrl
  }
  rememberRoutPath(path: string) {
    this.activePath = path
  }

}
