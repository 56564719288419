import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UserService } from '../userService';

var routeParams;
var queryParams;
@Component({
  selector: 'app-public-sign-up',
  templateUrl: './public-sign-up.component.html',
  styleUrls: ['./public-sign-up.component.css']
})
export class PublicSignUpComponent implements OnInit {
  public signUpType: string = ''
  public signUpSettings: Object = {}
  public verificationEmail: string = '';
  constructor(private activeRoute: ActivatedRoute,
    private router: Router,
    private userService: UserService) {
    queryParams = activeRoute.queryParams.subscribe(data => {
      if (data['email'])
        this.verificationEmail = data['email']
      else this.verificationEmail = ''
    })
    routeParams = activeRoute.params.subscribe(data => {
      if (data.type == 'individual' || data.type == 'company' || data.type == 'verify')
        this.signUpType = data.type
      else this.signUpType = 'individual'
      if (this.signUpType == 'verify')
        this.signUpSettings = { type: this.signUpType, verificationEmail: this.verificationEmail, fullForm: true }
      else this.signUpSettings = { type: this.signUpType, fullForm: true }
    })
  }
  toggleSignUpType(): void {
    if (this.signUpSettings['type'] == 'individual')
      this.router.navigate(['/landing/signUp/company'])
    else if (this.signUpSettings['type'] == 'company')
      this.router.navigate(['/landing/signUp/individual'])
  }
  handleSignUpEvent(eventData: Object): void {
    if (eventData['info'] == 'userLogged') {
      if (this.userService.afterLogInRoute) {
        let route = this.userService.afterLogInRoute
        this.userService.setAfterLogInRoute('')
        this.router.navigateByUrl(route)
      }
      else this.router.navigateByUrl('/clientPanel/dashboard/all@1/none')
    }
    else if (eventData['info'] == 'verifyEmail') {
      let queryData = {}
      if (eventData['data']['email'])
        queryData['email'] = eventData['data']['email']
      this.router.navigate(['/landing/signUp/verify'], { queryParams: queryData })
    }

  }
  ngOnInit() {
  }
  ngOnDestroy() {
    if (routeParams) routeParams.unsubscribe()
    if (queryParams) queryParams.unsubscribe()
  }

}
