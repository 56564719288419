import { Injectable } from '@angular/core';

declare var ga: any;
declare var tcjs: any;
var trackingEventsData={
  paymentDeep: {
    hitType: 'event',
    eventCategory: 'Successful Payment',
    eventAction: 'Payment Deep',
    eventLabel: "Event Successful Payment Deep"
  },
  signUpSuccess: {
    hitType: 'event',
    eventCategory: 'Successful Registration',
    eventAction: 'Registration Completed',
    eventLabel: "Event Successful Registration"
  }
}
@Injectable({
  providedIn: 'root'
})
export class TrackService {

  constructor() { }
  pageChange(url: string): void {
    if (ga != undefined) {
      ga('send', 'pageview', {
        page: url
      });
    }
    // if (tcjs != undefined)
    //   tcjs("trigger", "viewChange", url)
  }
  event(eventType: string): void {
    if (ga != undefined && trackingEventsData[eventType]) {
      ga('send', trackingEventsData[eventType]);
    }
  }
}
