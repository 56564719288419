import { Component, OnInit, Input, OnDestroy, NgZone } from '@angular/core';
import { HttpService } from '../httpService';
import { HelperService } from '../helper-service.service';
import { UserService } from '../userService';
import { Router } from '@angular/router';
import { DatePipe } from '@angular/common';
import { CaptchaService } from '../captcha.service';

declare var $: any;
declare var intlTelInput;
var captchaEvent;
var prefilInfo = [
  { key: 'person_name', type: 'key', dataType: 'string' },
  { key: 'occupation', type: 'key', dataType: 'string' },
  { key: 'dob', type: 'key', dataType: 'date' },
  { key: 'person_address', type: 'key', dataType: 'string' },
  { key: 'employment_history', type: 'array', dataType: 'string' },
  { key: 'person_email', type: 'array', dataType: 'string' },
]
@Component({
  selector: 'app-buy-deep-dash',
  templateUrl: './buy-deep-dash.component.html',
  styleUrls: ['./buy-deep-dash.component.css']
})
export class BuyDeepDashComponent implements OnInit {
  public helperData = { person_email: [], employment_history: [], education: [], relatives: [] }
  public deepReport: Object = { from_web: true, person_email: [], phone_number: [], employment_history: [], education: [], relatives: [], photo_upload: [], document_upload: [] };
  public validationErrors: Object = {};
  public newEmail: string;
  public newEducation: string;
  public newEmployment: string;
  public newRelative: string;
  public auth: boolean;
  public error: null;
  public initialPrice: number = 39.95;
  public displayPrice: string = '39.95';
  public showCallForm: boolean;
  public clickDisabled: boolean;
  public copyReport = {};
  @Input() set prefilReport(object: Object) {
    if (object['prefil'])
      this.prefilReportData(object['data'])
  }
  constructor(private httpService: HttpService,
    public helperService: HelperService,
    private userService: UserService,
    private router: Router,
    private datePipe: DatePipe,
    private captchaService: CaptchaService,
    private zone: NgZone) {
    if (this.userService.storageKey) this.auth = true
    else {
      this.auth = false
      if (this.captchaService.captchaReady)
        this.captchaService.getCaptchaCode()
      captchaEvent = this.captchaService.captchaEvent.subscribe(data => {
        this.zone.run(() => { this.deepReport['captcha_token'] = data['captcha_token'] })
      })
    }
  }
  prefilReportData(data: Object) {
    for (let i = 0; i < prefilInfo.length; i++) {
      if (data[prefilInfo[i].key]) {
        if (prefilInfo[i].type == 'key') {
          if (prefilInfo[i].dataType == 'date')
            this.deepReport[prefilInfo[i].key] = this.datePipe.transform(new Date(data[prefilInfo[i].key]), 'yyyy-MM-dd')
          else this.deepReport[prefilInfo[i].key] = data[prefilInfo[i].key]
        }
        else if (prefilInfo[i].type == 'array')
          this.helperData[prefilInfo[i].key].push({ value: data[prefilInfo[i].key] })
      }
    }
  }
  addEmail(email: string): void {
    if (email) {
      this.helperData['person_email'].push({ value: email })
      this.newEmail = ''
    }
  }
  addEducation(education: string): void {
    if (education) {
      this.helperData['education'].push({ value: education })
      this.newEducation = ''
    }
  }
  addEmployment(employment: string): void {
    if (employment) {
      this.helperData['employment_history'].push({ value: employment })
      this.newEmployment = ''
    }
  }
  addRelative(relative: string): void {
    if (relative) {
      this.helperData['relatives'].push({ value: relative })
      this.newRelative = ''
    }
  }
  removeImage(index: number): void {
    this.deepReport['photo_upload'].splice(index, 1)
  }
  removeDocument(index: number): void {
    this.deepReport['document_upload'].splice(index, 1)
    // delete this.deepReport['document_upload']
  }
  removeWhitelabel(): void {
    delete this.deepReport['whitelabel_upload']
  }
  updateImage(index: number, event) {
    this.deepReport['photo_upload'][index] = event.target.files[0]
  }

  updateFile(index: number, event) {
    this.deepReport['document_upload'][index] = event.target.files[0]
  }

  addImages(event) {
    for (let i = 0; i < event.target.files.length; i++) {
      this.deepReport['photo_upload'].push(event.target.files[i])
    }
  }
  addFile(event) {
    for (let i = 0; i < event.target.files.length; i++) {
      this.deepReport['document_upload'].push(event.target.files[i])
    }
    // this.deepReport['document_upload'] = event.target.files[0]
  }
  addWhiteLabel(event) {
    this.deepReport['whitelabel_upload'] = event.target.files[0]
  }
  addPhone() {
    let newPhone = $('#newPhone').val()
    if (newPhone && newPhone != '+1') {
      this.deepReport['phone_number'].push(newPhone)
      this.initPhone('newPhone', '+1')
    }
  }
  updateData() {
    var formatArrays = ['person_email', 'employment_history', 'education', 'relatives']
    this.addEmail(this.newEmail)
    for (let i = 0; i < this.deepReport['phone_number'].length; i++) {
      this.deepReport['phone_number'][i] = $('#phone' + i).val()
    }
    this.addPhone()
    this.addEducation(this.newEducation)
    this.addEmployment(this.newEmployment)
    this.addRelative(this.newRelative)
    for (let i = 0; i < formatArrays.length; i++) {
      let array = []
      for (let m = 0; m < this.helperData[formatArrays[i]].length; m++) {
        array.push(this.helperData[formatArrays[i]][m].value)
      }
      this.deepReport[formatArrays[i]] = array;
      this.copyReport[formatArrays[i]] = array;
    }
  }



  finishReport() {
    $("#mainContainer").addClass('fullLoader')
    this.copyReport = {...this.deepReport};
    if (this.copyReport['whitelabel']) this.copyReport['whitelabel'] = 1;
    else this.copyReport['whitelabel'] = 0;
    if (this.copyReport['add_court_records']) this.copyReport['add_court_records'] = 1;
    else this.copyReport['add_court_records'] = 0;
    if (this.copyReport['add_criminal_records']) this.copyReport['add_criminal_records'] = 1;
    else this.copyReport['add_criminal_records'] = 0;
    if (this.copyReport['plain_report']) this.copyReport['plain_report'] = 1;
    else this.copyReport['plain_report'] = 0;
    this.updateData();
    let authorization = ''
    if (this.auth)
      authorization = 'authorization'
    this.httpService.postObjectToUrl(this.httpService.postApi + 'v1/reports', this.copyReport, 'formData', authorization).then(data => {
      this.validationErrors = {};
      if (!this.auth) this.deepReport['captcha_token'] = ''
      if (data['result'] == 'success') {
        let checkoutKey = 'deep'
        if (this.deepReport['whitelabel'] || this.deepReport['plain_report'])
          checkoutKey = 'deepLabeled'
        if (this.auth) {
          this.helperService.setToastr('success', 'toastr.success.deepReport.title', 'toastr.success.deepReport.body')
          this.router.navigateByUrl('/clientPanel/checkout/' + checkoutKey + '/' + data['response']['id'] + '/none')
        }
        else {
          this.helperService.setToastr('success', 'toastr.success.deepReportNotLogged.title', 'toastr.success.deepReportNotLogged.body')
          this.userService.setAfterLogInRoute('/clientPanel/checkout/' + checkoutKey + '/' + data['response']['id'] + '/none')
          this.router.navigateByUrl('/landing/logIn/plain')
        }
      }
      else if (data['result'] == 'error') {
        if (!this.auth)
          this.captchaService.getCaptchaCode()
        this.validationErrors = data['response']['errors']
        let title = 'Please fill all the required data'
        let body = ''
        if (data['response']) {
          if (data['response']['title']) {
            title = data['response']['title']
          }
          if (data['response']['errors'] && typeof (data['response']['errors']) == 'object') {
            for (let element in data['response']['errors']) {
              if (Array.isArray(data['response']['errors'][element]) && data['response']['errors'][element].length > 0) {
                for (let message of data['response']['errors'][element]) {
                  body += `${message}<br />`
                }
              }
            }
          }
        }
        this.helperService.setToastrStrings('error', title, body)
      }
      $("#mainContainer").removeClass('fullLoader')
    })
  }
  initPhone(id, value) {
    if (value)
      $('#' + id).val(value)
    intlTelInput(document.querySelector("#" + id), {
      autoHideDialCode: false,
      nationalMode: true,
    });
  }

  calculatePrice(): void {
    let price = this.initialPrice;

    if (this.deepReport['whitelabel']) {
      price += 15.00;
    }

    if (this.deepReport['plain_report']) {
      price += 15.00;
    }

    if (this.deepReport['add_court_records']) {
      price += 9.95;
    }

    if (this.deepReport['add_criminal_records']) {
      price += 4.95;
    }

    this.displayPrice = price.toFixed(2);
  }

  setCallPanel(value: boolean): void {
    this.showCallForm = value
    if (this.showCallForm)
      document.getElementsByTagName("body")[0].style.overflowY = 'hidden'
    else document.getElementsByTagName("body")[0].style.overflowY = 'auto';
  }

  setDisable(value: boolean): void {
    this.clickDisabled = value
  }

  handleRequestEvent(event) {
    if (event == 'success')
      this.setCallPanel(false)
  }

  ngOnInit() {
    setTimeout(() => {
      this.setCallPanel(true);
    }, 2);
  }

  ngOnDestroy() {
    if (captchaEvent) captchaEvent.unsubscribe()
  }
  ngAfterViewInit() {
    this.initPhone('newPhone', '+1')
  }

}
