import { Component, OnInit } from '@angular/core';
import { PopUpService } from 'src/app/pop-up.service';

@Component({
  selector: 'app-main-footer',
  templateUrl: './main-footer.component.html',
  styleUrls: ['./main-footer.component.css']
})
export class MainFooterComponent implements OnInit {
  public currentYear = new Date().getFullYear();

  constructor(public popUpService: PopUpService) { }

  ngOnInit() {
  }

}
