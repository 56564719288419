import { Component, OnInit, OnDestroy } from '@angular/core';
import { PopUpService } from '../pop-up.service';
import { ActivatedRoute } from '@angular/router';

var queryParams;
@Component({
  selector: 'app-order-deep',
  templateUrl: './order-deep.component.html',
  styleUrls: ['./order-deep.component.css']
})
export class OrderDeepComponent implements OnInit, OnDestroy {
  public prefilData = { prefil: false, data: {} }
  constructor(public popUpService: PopUpService,
    private activeRoute: ActivatedRoute) {
    queryParams = activeRoute.queryParams.subscribe(data => {
      for (let key in queryParamKeys) {
        if (data[key]) {
          this.prefilData['data'][key] = data[key]
          if (!this.prefilData['prefil']) this.prefilData['prefil'] = true
        }
      }
    })
  }

  ngOnInit() {
  }

  ngOnDestroy(): void {
    if (queryParams) queryParams.unsubscribe()
  }

}

const queryParamKeys = { person_name: '', occupation: '', dob: '', person_address: '', employment_history: '', person_email: '' }
