import { Component, OnInit } from '@angular/core';
import { UserService } from '../userService';
import { Router, ActivatedRoute } from '@angular/router';
import { DialogBoxComponent } from '../dialog-box/dialog-box.component';
import { HelperService } from '../helper-service.service';
import { TrackService } from '../track.service';

declare var $: any;
@Component({
  selector: 'app-client-panel',
  templateUrl: './client-panel.component.html',
  styleUrls: ['./client-panel.component.css']
})
export class ClientPanelComponent implements OnInit {
  public activeMenu: string;
  public showResponsiveMenu: boolean;
  public disableResponsive: boolean;
  public showCallForm: boolean;
  public showTalkImage: boolean;
  public clickDisabled: boolean;
  public snfData = { email: 'info@socialnetworkfinder.com' }
  constructor(public userService: UserService,
    private router: Router,
    private activeRoute: ActivatedRoute,
    private dialog: DialogBoxComponent,
    public helperService: HelperService,
    private trackService: TrackService) {
    if (userService.storageKey) {
      $("#mainContainer").addClass('fullLoader')
      userService.getUser().then(data => {
        if (data['result'] == 'success')
          userService.setUser(data['response'])
        $("#mainContainer").removeClass('fullLoader')
      })
    }
    else this.router.navigateByUrl('/landing/home')
  }

  ngOnInit() {
    // setTimeout(() => {
    //   this.setCallPanel(true);
    // }, 6000);
  }

  setCallPanel(value: boolean): void {
    this.showCallForm = value
    if (this.showCallForm)
      document.getElementsByTagName("body")[0].style.overflowY = 'hidden'
    else document.getElementsByTagName("body")[0].style.overflowY = 'auto';
  }

  setDisable(value: boolean): void {
    this.clickDisabled = value
  }

  handleRequestEvent(event) {
    if (event == 'success')
      this.setCallPanel(false)
  }

  toggleResponsiveMenu() {
    this.showResponsiveMenu = !this.showResponsiveMenu
  }
  setResponsiveClick(value) {
    this.disableResponsive = value
  }
  routerChange() {
    window.scrollTo(0, 0)
    this.activeMenu = this.activeRoute.firstChild.data['_value']['main']
    if (this.showResponsiveMenu) this.showResponsiveMenu = false
    this.trackService.pageChange(this.router.url)
    if (this.activeMenu !== 'contact' && this.activeMenu != 'requstCall')
      this.showTalkImage = true
    else this.showTalkImage = false
    if (this.showCallForm)
      this.setCallPanel(false)
    this.trackService.pageChange(this.router.url)
  }
  logOut() {
    if (this.showResponsiveMenu)
      this.showResponsiveMenu = false
    this.dialog.openDialog(this.helperService.translateKey('dialog.logOut.title'), this.helperService.translateKey('dialog.logOut.body')).then(dialogResponse => {
      if (dialogResponse == 'Yes')
        this.userService.logOut()
    })
  }
  ngOnDestroy() {
    if (document.getElementsByTagName("body")[0].style.overflowY == 'hidden')
      document.getElementsByTagName("body")[0].style.overflowY = 'auto';
  }
}
