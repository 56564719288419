import { Component, OnInit, OnDestroy } from '@angular/core';
import { PopUpService } from '../pop-up.service';
import { HelperService } from '../helper-service.service';

@Component({
  selector: 'app-product-price',
  templateUrl: './product-price.component.html',
  styleUrls: ['./product-price.component.css']
})
export class ProductPriceComponent implements OnInit, OnDestroy {
  public searchInfo: Array<Object> = [
    { name: 'manulyPrepared', image: '/assets/images/manuallyPrepared.png' },
    { name: 'visualPresentation', image: '/assets/images/visualPresentation.png' },
    { name: 'whiteLabel', image: '/assets/images/possibilityWhitelabel.png' },
    { name: 'worlwide', image: '/assets/images/peopleWorldwide.png' }
  ]
  public reportElements: Array<String> = [
    'name',
    'manually',
    'detailed',
    'doubleChecked',
    'fullyBranded',
    'status',
    'specifiedInstructions',
    'upTo72Hours'
  ]
  public allInOneElements: Array<String> = [
    'leftBlock',
    'rightBlock',
  ]
  constructor(public popUpService: PopUpService,
    public helperService: HelperService) { }
  ngOnInit() {
  }
  ngOnDestroy() {
    this.popUpService.closePopUp()
  }
}
