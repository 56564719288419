import { Directive, ElementRef } from '@angular/core';
let newValue = ''; let element
@Directive({
    selector: '[credit-card-formatter]',
    host: { '(ngModelChange)': 'formatValue($event)' }
})
export class CreditCardDirective {
    constructor(el: ElementRef) {
        element = el.nativeElement;
        newValue=''
    }
    formatValue(value) {
        if (value.replace(/\s/g, "").length < 17&& /^[0-9 ]*$/.test(value)) {
            newValue = ''
            let count = 0
            for (let i = 0; i < value.length; i++) {
                if (value[i] != ' ') {
                    if (count > 0 && count % 4 == 0) newValue += ' '
                    newValue += value[i]
                    count++
                }
            }
        }
        element.value = newValue;
    }
}