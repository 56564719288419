import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-warning-popup',
  templateUrl: './warning-popup.component.html',
  styleUrls: ['./warning-popup.component.css']
})
export class WarningPopupComponent implements OnInit {

  displayPopup: boolean = true;

  constructor() { }

  ngOnInit() {
  }

  closePopup(): void {
    this.displayPopup = false;
  }

}
