import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { HelperService } from '../helper-service.service';
import { PopUpService } from '../pop-up.service';

declare var $: any
var routeParams;
@Component({
  selector: 'app-instant-report',
  templateUrl: './instant-report.component.html',
  styleUrls: ['./instant-report.component.css']
})
export class InstantReportComponent implements OnInit, OnDestroy {
  public instantSearch: Object = {};
  public validationErrors: Object = {};
  public viewType: string;
  public searchInfo: Array<Object> = [
    { name: 'autoGenerating', image: '/assets/images/comments-b.png' },
    { name: 'instantAccess', image: '/assets/images/photos-b.png' },
    { name: 'verification', image: '/assets/images/direct-contact-b.png' },
    { name: 'accurate', image: '/assets/images/personal-views-b.png' }
  ]
  public validationRules = {
    person_email: ['required|email']
  }
  constructor(private router: Router,
    private activeRoute: ActivatedRoute,
    private helperService: HelperService,
    public popUpService: PopUpService) {
    routeParams = activeRoute.params.subscribe(data => {
      window.scrollTo(0, 0)
      this.instantSearch['person_email'] = '';
      this.validationErrors = {};
      if (data['type'] == 'report' || data['type'] == 'email-address-search')
        this.viewType = data['type']
      else this.viewType = 'report'
    })
  }
  ngOnInit() {
  }
  ngOnDestroy() {
    this.popUpService.closePopUp()
  }
  sendInstantSearch(searchData: Object): void {
    this.validationErrors = {}
    var validated = true
    for (let key in this.validationRules) {
      this.validationErrors[key] = this.helperService.validatateRules(searchData[key], this.validationRules[key], searchData)
      if (this.validationErrors[key] && validated)
        validated = false
    }
    if (validated)
      this.router.navigate(['/landing/results/' + searchData['person_email']])
  }
}
