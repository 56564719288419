import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ImageCropperModule } from 'ngx-image-cropper';
import { MatSharedModule } from './shared/mat-shared/mat-shared.module';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ToastrModule } from 'ngx-toastr';
import { LandingComponent } from './landing/landing.component';
import { HomeComponent } from './home/home.component';
import { ClientPanelComponent } from './client-panel/client-panel.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { InstantReportComponent } from './instant-report/instant-report.component';
import { AboutComponent } from './about/about.component';
import { DeepReportComponent } from './deep-report/deep-report.component';
import { CompareProductsComponent } from './compare-products/compare-products.component';
import { RequestCallComponent } from './request-call/request-call.component';
import { ContactComponent } from './contact/contact.component';
import { WhyUsComponent } from './why-us/why-us.component';
import { LogInComponent } from './log-in/log-in.component';
import { SignUpComponent } from './sign-up/sign-up.component';
import { FaqComponent } from './faq/faq.component';
import { LegalDataComponent } from './legal-data/legal-data.component';
import { DialogBoxComponent } from './dialog-box/dialog-box.component';
import { SettingsComponent } from './settings/settings.component';
import { PagesComponent } from './pages/pages.component';
import { InvoicesComponent } from './invoices/invoices.component';
import { InstantPreviewComponent } from './instant-preview/instant-preview.component';
import { CheckoutComponent } from './checkout/checkout.component';
import { CreditCardDirective } from './creditCardFormatter';
import { BuyInstantComponent } from './buy-instant/buy-instant.component';
import { ViewInstantComponent } from './view-instant/view-instant.component';
import { OrderDeepComponent } from './order-deep/order-deep.component';
import { BuyDeepComponent } from './buy-deep/buy-deep.component';
import { DeepFormComponent } from './deep-form/deep-form.component';
import { NgInitDirective } from './ng-init.directive';
import { ThumbnailImg } from './thumbNail';
import { TutorialsComponent } from './tutorials/tutorials.component';
import { GetExampleComponent } from './get-example/get-example.component';
import { GetInstantComponent } from './get-instant/get-instant.component';
import { CallFormComponent } from './call-form/call-form.component';
import { DatePipe } from '@angular/common';
import { SafePipe } from './safe.pipe';
import { MerchantComponent } from './merchant/merchant.component';
import { FormatPricePipe } from './format-price.pipe';
import { MainPopUpComponent } from './main-pop-up/main-pop-up.component';
import { SearchResultsComponent } from './search-results/search-results.component';
import { PublicResultsComponent } from './public-results/public-results.component';
import { LoggedResultsComponent } from './logged-results/logged-results.component';
import { PublicSignUpComponent } from './public-sign-up/public-sign-up.component';
import { ProductPriceComponent } from './product-price/product-price.component';

import { MainMenuComponent } from './shared/main-menu/main-menu.component';
import { MainFooterComponent } from './shared/main-footer/main-footer.component';
import { DashPopupComponent } from './shared/dash-popup/dash-popup.component';
import { BuyDeepDashComponent } from './buy-deep-dash/buy-deep-dash.component';
import { CallFormPopupComponent } from './call-form-popup/call-form-popup.component';
import { DashMenuComponent } from './shared/dash-menu/dash-menu.component';
import { ThankYouComponent } from './shared/thank-you/thank-you.component';
import { EditDeepReportComponent } from './edit-deep-report/edit-deep-report.component';
import { DeepReportPopupComponent } from './shared/deep-report-popup/deep-report-popup.component';
import { WarningPopupComponent } from './warning-popup/warning-popup.component';


// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

@NgModule({
  declarations: [
    AppComponent,
    LandingComponent,
    HomeComponent,
    ClientPanelComponent,
    DashboardComponent,
    InstantReportComponent,
    AboutComponent,
    DeepReportComponent,
    CompareProductsComponent,
    RequestCallComponent,
    ContactComponent,
    WhyUsComponent,
    LogInComponent,
    SignUpComponent,
    FaqComponent,
    ThumbnailImg,
    CreditCardDirective,
    LegalDataComponent,
    DialogBoxComponent,
    SettingsComponent,
    PagesComponent,
    InvoicesComponent,
    InstantPreviewComponent,
    CheckoutComponent,
    BuyInstantComponent,
    ViewInstantComponent,
    OrderDeepComponent,
    BuyDeepComponent,
    DeepFormComponent,
    NgInitDirective,
    TutorialsComponent,
    GetExampleComponent,
    GetInstantComponent,
    CallFormComponent,
    SafePipe,
    MerchantComponent,
    FormatPricePipe,
    MainPopUpComponent,
    SearchResultsComponent,
    PublicResultsComponent,
    LoggedResultsComponent,
    PublicSignUpComponent,
    ProductPriceComponent,
    MainMenuComponent,
    MainFooterComponent,
    DashPopupComponent,
    BuyDeepDashComponent,
    CallFormPopupComponent,
    DashMenuComponent,
    ThankYouComponent,
    EditDeepReportComponent,
    DeepReportPopupComponent,
    WarningPopupComponent
  ],
  imports: [
    MatSharedModule,
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    ImageCropperModule,
    BrowserAnimationsModule,
    HttpClientModule,
    ToastrModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    })
  ],
  providers: [DialogBoxComponent,DatePipe],
  bootstrap: [AppComponent]
})
export class AppModule { }
