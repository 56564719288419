import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

var routeParams
@Component({
  selector: 'app-public-results',
  templateUrl: './public-results.component.html',
  styleUrls: ['./public-results.component.css']
})
export class PublicResultsComponent implements OnInit, OnDestroy {
  public searchDetails: Object = { place: 'public' }
  constructor(private activeRoute: ActivatedRoute) {
    routeParams = activeRoute.params.subscribe(data => {
      if (data['email']) this.searchDetails['email'] = data['email']
    })
  }

  ngOnInit() {
  }
  ngOnDestroy() {
    if (routeParams) routeParams.unsubscribe()
  }

}
