import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'pages',
  templateUrl: './pages.component.html',
  styleUrls: ['./pages.component.css']
})
export class PagesComponent implements OnInit {
  public pageIndex: number = 2
  public pageObject = {}; pageNumbers = []; morePages;
  @Input() set pageData(object: Object) {
    this.pageObject = object
    this.setPages(this.pageObject['current_page'], this.pageObject['last_page'])
  }
  @Output() newPageUrl = new EventEmitter<string>();
  constructor() { }
  ngOnInit() { }
  getPageByNumber(number) {
    if (number !== this.pageObject['current_page']) {
      var url = ''
      var oldString = ''
      var newString = 'page=' + number;
      if (this.pageObject['next']) {
        oldString = "page=" + (this.pageObject['current_page'] + 1)
        url = this.pageObject['next'].replace(oldString, newString);
      }
      else if (this.pageObject['prev']) {
        oldString = "page=" + (this.pageObject['current_page'] - 1)
        url = this.pageObject['prev'].replace(oldString, newString);
      }
      if (url) this.getPage(url)
    }
  }
  getPage(url) {
    this.newPageUrl.emit(url)
  }
  setPages(showingPage, maxPages) {
    this.pageNumbers = []
    var start = 1
    var finish = maxPages
    if (showingPage > this.pageIndex) start = showingPage - this.pageIndex
    if (maxPages > start + (this.pageIndex * 2)) finish = start + (this.pageIndex * 2)
    for (start; start <= finish; start++) {
      this.pageNumbers.push(start)
    }
    if (this.pageNumbers[this.pageNumbers.length - 1] < maxPages) this.morePages = true;
    else this.morePages = false;
  }
}
