import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { HelperService } from '../helper-service.service';

declare var $: any;
var captchaEvent;
@Component({
  selector: 'app-get-instant',
  templateUrl: './get-instant.component.html',
  styleUrls: ['./get-instant.component.css']
})
export class GetInstantComponent implements OnInit, OnDestroy {
  public instantData: Object = {};
  public validationErrors: Object = {};
  public validationRules = {
    person_email: ['required|email']
  }
  constructor(private router: Router,
    private helperService: HelperService) { }
  sendInstantSearch(instantData: Object): void {
    this.validationErrors = {}
    var validated = true
    for (let key in this.validationRules) {
      this.validationErrors[key] = this.helperService.validatateRules(instantData[key], this.validationRules[key], instantData)
      if (this.validationErrors[key] && validated)
        validated = false
    }
    if (validated)
      this.router.navigate(['/landing/results/' + instantData['person_email']])
  }
  ngOnInit() {
  }
  ngOnDestroy() {
    if (captchaEvent) captchaEvent.unsubscribe()
  }

}
