import { Injectable, EventEmitter } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class PopUpService {
  public action = new EventEmitter;
  constructor() { }
  initPopUp(popUpSettings: Object): void {
    document['onmouseleave'] = () => {
      this.action.emit({ action: 'open', settings: popUpSettings })
    }
  }
  openPopUp(popUpSettings: Object): void {
    this.action.emit({ action: 'open', settings: popUpSettings })
  }
  closePopUp() {
    this.action.emit({ action: 'close', settings: {} })
  }
  destroyPopUp() {
    document['onmouseleave'] = undefined;
    this.closePopUp()
  }
}
