import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { MenuElement } from './models';

declare var $: any;
var validationRules = {
  password: { minlength: 8 },
  email: { pattern: /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i }
}
@Injectable({
  providedIn: 'root'
})
export class HelperService {
  public languages: Array<Object> = []
  constructor(private toastr: ToastrService, private translate: TranslateService) { }
  setToastr(type: string, head: string, body: string): void {
    this.toastr[type](this.translate.instant(body), this.translate.instant(head));
  }
  
  setToastrStrings(type: string, head: string, body: string): void {
    this.toastr[type](body, head, {enableHtml: true});
  }

  
  serverError(): void {
    console.log('Server error')
  }
  serializeObject(object) {
    var response = '?';
    for (let key in object) {
      if (object[key] || typeof object[key] === 'boolean')
        if (!response) response = key + '=' + object[key]
        else response += '&' + key + '=' + object[key]
    }
    return response
  }
  generateBlob(image) {
    var promise = new Promise((resolve) => {
      let reader = new FileReader();
      reader.onload = (e: any) => {
        resolve(e.target.result);
      }
      reader.readAsDataURL(image);
    })
    return promise
  }
  translateKey(key) {
    return this.translate.instant(key)
  }
  smoothScrollToElement(id) {
    $('html, body').animate({
      scrollTop: $('#'+id).offset().top
    }, 400 );
  }
  subValidation(value, rules, validationKey) {
    for (let key in rules) {
      switch (key) {
        case 'minlength': {
          if (value.length < rules[key]) {
            return [this.translate.instant('validation.minlength') + ' ' + rules[key]]
          }
          break;
        }
        case 'pattern': {
          if (!rules[key].test(String(value).toLowerCase())) {
            return [this.translate.instant('validation.pattern.' + validationKey) + ' ']
          }
          break;
        }
      }
    }
    return []
  }
  validatateRules(value, rules, compareData) {
    let responseData = []
    for (let i = 0; i < rules.length; i++) {
      if (rules[i].includes('required')) {
        if (!value) {
          responseData.push(this.translate.instant('requiredField'))
          break;
        }
        else if (rules[i].includes('|')) {
          let subRule = rules[i].split('|').pop()
          responseData = this.subValidation(value, validationRules[subRule], subRule)
          if (responseData.length > 0)
            break;
        }
      }
      if (rules[i].includes('same')) {
        let sameKey = rules[i].split('|').pop()
        if (value != compareData[sameKey]) {
          responseData.push(this.translate.instant('validation.sameValue') + ' ' + sameKey);
          break
        }
      }
    }
    if (responseData.length > 0)
      return responseData
    else return undefined
  }
  public clientMenu: Array<MenuElement> = [
    // {
    //   "name": "home",
    //   "hasSubMenu": false,
    //   "hrefLink": "./landing/home",
    //   "icon": "fas fa-desktop"
    // },
    {
      "name": "dashboard",
      "hasSubMenu": false,
      "hrefLink": "./dashboard/all@1/none",
      "icon": "fas fa-desktop"
    },
    {
      "name": "invoices",
      "hasSubMenu": false,
      "hrefLink": "./invoices/1",
      "icon": "fas fa-stream"
    },
    {
      "name": "settings",
      "hasSubMenu": false,
      "hrefLink": "./settings",
      "icon": "fas fa-sliders-h"
    },
  ]
  public mainMenu: Array<MenuElement> = [
    {
      "name": "home",
      "hasSubMenu": false,
      "hrefLink": "./home",
      "icon": "",
      "access": "all"
    },
    {
      "name": "productPricing",
      "hasSubMenu": false,
      "hrefLink": "./productPricing",
      "icon": "",
      "access": "all"
    },
    // {
    //   "name": "products",
    //   "hasSubMenu": true,
    //   "hrefLink": "",
    //   "icon": "",
    //   "access":"all",
    //   "subMenu": [
    //     {
    //       "name": "deepReport",
    //       "hasSubMenu": false,
    //       "hrefLink": "./deepReport",
    //       "icon": "",
    //     },
    //     {
    //       "name": "instantReport",
    //       "hasSubMenu": false,
    //       "hrefLink": "./instant/report",
    //       "icon": "",
    //     },
    //     {
    //       "name": "compare",
    //       "hasSubMenu": false,
    //       "hrefLink": "./compareProducts",
    //       "icon": "",
    //     },
    //   ],
    // },
    {
      "name": "aboutUs",
      "hasSubMenu": false,
      "hrefLink": "./about",
      "icon": "",
      "access": "all"
    },
    {
      "name": "contact",
      "hasSubMenu": false,
      "hrefLink": "./contact",
      "icon": "",
      "access": "all"
    },
    {
      "name": "logIn",
      "hasSubMenu": false,
      "hrefLink": "./logIn/plain",
      "icon": "",
      "access": "notLogged"
    },
    {
      "name": "signUp",
      "hasSubMenu": false,
      "hrefLink": "./signUp/individual",
      "icon": "",
      "access": "notLogged"
    },
    {
      "name": "dashboard",
      "hasSubMenu": false,
      "hrefLink": "/clientPanel/dashboard/all@1/none",
      "icon": "",
      "access": "logged"
    },
  ]
  public mainObject = {}
}
