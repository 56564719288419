import { Component, OnInit, OnDestroy, NgZone } from '@angular/core';
import { logUser } from '../models';
import { HttpService } from '../httpService';
import { ActivatedRoute, Router } from '@angular/router';
import { UserService } from '../userService';
import { HelperService } from '../helper-service.service';
import { CaptchaService } from '../captcha.service';

declare var $: any
var routeParams;
var captchaEvent;
@Component({
  selector: 'app-log-in',
  templateUrl: './log-in.component.html',
  styleUrls: ['./log-in.component.css']
})
export class LogInComponent implements OnInit, OnDestroy {
  public user = {} as logUser
  public logInValidation: boolean = false;
  public newPassword: Object = {};
  public logType: string = ''
  public validationErrors: Object = {};
  public logInMessage: string = '';
  public captchaToken: string = '';
  public verifyEmail: String = '';
  constructor(private httpService: HttpService,
    private activeRoute: ActivatedRoute,
    public userService: UserService,
    private router: Router,
    public helperService: HelperService,
    private captchaService: CaptchaService,
    private zone: NgZone) {
    routeParams = activeRoute.params.subscribe(data => {
      this.captchaToken = ''
      if (data.type == 'plain' || data.type == 'resetEmail')
        this.logType = data.type
      else {
        if (data.type.includes("@")) {
          let array = data.type.split("@")
          if (array.length == 2) {
            if (array[0] == 'resetPassword') {
              this.logType = 'newPassword'
              this.newPassword['token'] = array[1]
            }
          }
          else this.logType = 'plain'
        }
        else this.logType = 'plain'
      }
      this.validationErrors = {}
      if (this.captchaService.captchaReady)
        this.captchaService.getCaptchaCode()
      captchaEvent = this.captchaService.captchaEvent.subscribe(data => {
        this.zone.run(() => { this.captchaToken = data['captcha_token'] })
      })
    })
    if (this.userService.storageKey)
      this.router.navigateByUrl('/clientPanel/dashboard/all@1/none')
  }

  ngOnInit() {
  }
  ngOnDestroy() {
    if (routeParams) routeParams.unsubscribe()
    if (captchaEvent) captchaEvent.unsubscribe()
  }
  setLogInValidation(value: boolean): void {
    this.logInValidation = value
  }
  setVerifyEmail(value: String): void {
    this.verifyEmail = value
  }
  signIn(user: logUser): void {
    if (this.captchaToken) {
      $("#mainContainer").addClass('fullLoader')
      user['captcha_token'] = this.captchaToken
      this.httpService.postObjectToUrl(this.httpService.postApi + 'auth/login', user, 'json', '').then(data => {
        this.logInValidation = false;
        this.validationErrors = {};
        this.logInMessage = ''
        this.captchaToken = '';
        this.verifyEmail = '';
        if (data['result'] == 'success') {
          if (data['response']['access_token']) {
            localStorage.setItem('apiSnfKey', btoa(data['response']['access_token']))
            this.userService.checkToken('apiSnfKey')
            if (this.userService.afterLogInRoute) {
              let route = this.userService.afterLogInRoute
              this.userService.setAfterLogInRoute('')
              this.router.navigateByUrl(route)
            }
            else this.router.navigateByUrl('/clientPanel/dashboard/all@1/none')
          }
        }
        else if (data['result'] == 'error') {
          this.captchaService.getCaptchaCode()
          if (data['response']['errors'])
            this.validationErrors = data['response']['errors']
          else if (data['response']['message']) {
            if (data['response']['message'] == 'verify_email')
              this.setVerifyEmail(user['email'])
            else this.logInMessage = data['response']['message']
          }
          this.logInValidation = true
        }
        $("#mainContainer").removeClass('fullLoader')
      })
    }
  }
  sendEmail(emailObject: Object): void {
    if (this.captchaToken) {
      $("#mainContainer").addClass('fullLoader')
      emailObject['captcha_token'] = this.captchaToken
      this.httpService.postObjectToUrl(this.httpService.postApi + 'auth/password/email', emailObject, 'json', '').then(data => {
        this.validationErrors = {}
        this.captchaToken = ''
        this.captchaService.getCaptchaCode()
        if (data['result'] == 'success')
          this.helperService.setToastr('success', 'toastr.success.emailReset.title', 'toastr.success.emailReset.body')
        else if (data['result'] == 'error')
          this.validationErrors = data['response']['errors']
        $("#mainContainer").removeClass('fullLoader')
      })
    }
  }
  resetPassword(newPass: Object): void {
    if (this.captchaToken) {
      $("#mainContainer").addClass('fullLoader')
      newPass['captcha_token'] = this.captchaToken
      this.httpService.postObjectToUrl(this.httpService.postApi + 'auth/password/reset', newPass, 'json', '').then(data => {
        this.validationErrors = {}
        this.captchaToken = ''
        if (data['result'] == 'success') {
          this.router.navigateByUrl('/landing/logIn/plain')
          this.helperService.setToastr('success', 'toastr.success.newPass.title', 'toastr.success.newPass.body')
        }
        else if (data['result'] == 'error') {
          this.captchaService.getCaptchaCode()
          this.validationErrors = data['response']['errors']
        }
        $("#mainContainer").removeClass('fullLoader')
      })
    }
  }

}
