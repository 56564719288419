import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.css']
})
export class FaqComponent implements OnInit {
  public questions: Array<string> = [
    'howItWorks',
    'whatYouAreLooking',
    'deepReport',
    'whereYouGetInformation',
    'whatWebites',
    'whatInfoIsNeeded',
    //'instantReport',
    'howMuchCosts',
    'howLong',
    //'differenceBasicDeep',
    'discount',
    'whatComes',
    // 'refundPolicy',
    // 'tutorials',
    'makeDataPrivate',
    'whoCanSee',
    'willSomeoneKnow',
    'howToPay',
    'monthlySubscription',
    'phoneSearch',
    'kindOfSearches',
    'howToGetTheReport',
    'recoverAccount',
    'consumerReportingAgency'
  ]
  constructor() { }

  ngOnInit() {
  }

}
