import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { UserService } from 'src/app/userService';
import { Router } from '@angular/router';
import { HelperService } from 'src/app/helper-service.service';
import { DialogBoxComponent } from 'src/app/dialog-box/dialog-box.component';



export interface MenuElement {
  name: string,
  hasSubMenu: boolean,
  icon: string,
  hrefLink: string,
  subMenu?: Array<MenuElement>,
  access?: string,
  queryParams?: Object
}

@Component({
  selector: 'app-main-menu',
  templateUrl: './main-menu.component.html',
  styleUrls: ['./main-menu.component.css']
})
export class MainMenuComponent implements OnInit {
  public showResponsiveMenu: boolean;
  centered = false;

  constructor(
    public userService: UserService,
    private router: Router,
    public helperService: HelperService,
    private dialog: DialogBoxComponent,) {
    if (userService.storageKey) {
      // $("#mainContainer").addClass('fullLoader')
      userService.getUser().then(data => {
        if (data['result'] == 'success')
          userService.setUser(data['response'])
        // $("#mainContainer").removeClass('fullLoader')
      })
    }
    // else this.router.navigateByUrl('/landing/home')
  }

  ngOnInit() {
  }

  logOut() {
    if (this.showResponsiveMenu)
      this.showResponsiveMenu = false
    this.dialog.openDialog(this.helperService.translateKey('dialog.logOut.title'), this.helperService.translateKey('dialog.logOut.body')).then(dialogResponse => {
      if (dialogResponse == 'Yes')
        this.userService.logOut()
    })
  }

}
