import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpService } from '../httpService';
import { TableHeader } from '../models';

var routeParams;
declare var $: any;
@Component({
  selector: 'app-invoices',
  templateUrl: './invoices.component.html',
  styleUrls: ['./invoices.component.css']
})
export class InvoicesComponent implements OnInit, OnDestroy {
  public statusFilter: string;
  public activeQuery: boolean;
  public pageObject: Object = {};
  public invoices: Array<Object> = [];
  public pageSize: number = 10;
  public invoiceHeaders: Array<TableHeader> = [
    { name: 'invoiceId' },
    { name: 'personName' },
    { name: 'date' },
    // { name: 'reportType' },
    { name: 'status' },
    { name: 'whitelabel' },
    { name: 'plain_report' },
    // { name: 'court_records' },
    // { name: 'criminal_records' },
    { name: 'paidAmount' },
  ];
  constructor(private router: Router,
    private httpService: HttpService,
    private activeRoute: ActivatedRoute) {
    routeParams = activeRoute.params.subscribe(data => {
      window.scrollTo(0, 0)
      if (!this.pageObject['current_page'] || this.pageObject['current_page'] != data.page)
        this.getInvoices(this.httpService.postApi + 'v1/invoices?page=' + data.page + '&pagesize=' + this.pageSize)
    })
  }
  getInvoice(id: number): void {
    $("#mainContainer").addClass('fullLoader')
    this.httpService.getObjectFromUrl(this.httpService.postApi + 'v1/invoice/' + id, 'authorization').then(data => {
      if (data['result'] == 'success') {
        let a = document.createElement('a');
        a.setAttribute("href", data['response']['link']);
        a.setAttribute("download", '');
        a.setAttribute("target", '_top')

        a.classList.add('invisibleLink')
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        a.remove();
      }
      $("#mainContainer").removeClass('fullLoader')
    })
  }
  fillObject(data: Object): void {
    this.invoices = data['data'];
    this.pageObject = {};
    for (let key in data['links']) {
      this.pageObject[key] = data['links'][key]
    }
    for (let key in data['meta']) {
      this.pageObject[key] = data['meta'][key]
    }
    this.router.navigateByUrl('/clientPanel/invoices/' + this.pageObject['current_page'])
  }
  getInvoices(url: string): void {
    this.activeQuery = true
    $("#localSpiner").show()
    this.httpService.getObjectFromUrl(url, 'authorization').then(data => {
      if (data['result'] == 'success')
        this.fillObject(data['response'])
      this.activeQuery = false
      $("#localSpiner").hide()
    })
  }
  ngOnInit() {
  }
  ngOnDestroy() {
    if (routeParams) routeParams.unsubscribe()
  }
}
