import { Component, OnInit, NgZone, OnDestroy } from '@angular/core';
import { HttpService } from '../httpService';
import { HelperService } from '../helper-service.service';
import { CaptchaService } from '../captcha.service';

declare var $: any
var captchaEvent;
@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.css']
})
export class ContactComponent implements OnInit, OnDestroy {
  public contact: Object = {};
  public validationErrors: Object = {};
  public snfData = { phonePlain: 8008080862, phoneFormated: '800-808-0862', email: 'info@socialnetworkfinder.com', site: 'www.socialnetworkfinder.com' };
  constructor(private httpService: HttpService,
    public helperService: HelperService,
    private captchaService: CaptchaService,
    private zone: NgZone) {
    if (this.captchaService.captchaReady)
      this.captchaService.getCaptchaCode()
    captchaEvent = this.captchaService.captchaEvent.subscribe(data => {
      this.zone.run(() => { this.contact['captcha_token'] = data['captcha_token'] })
    })
  }
  ngOnInit() { }
  ngOnDestroy() {
    if (captchaEvent) captchaEvent.unsubscribe()
  }
  sendContact(contact: Object): void {
    $("#mainContainer").addClass('fullLoader')
    this.httpService.postObjectToUrl(this.httpService.postApi + 'v1/contact/email', contact, 'json', '').then(data => {
      this.validationErrors = {};
      this.contact['captcha_token']=''
      this.captchaService.getCaptchaCode()
      if (data['result'] == 'success') {
        this.contact = {};
        this.helperService.setToastr('success', 'toastr.success.contact.title', 'toastr.success.contact.body')
      }
      else if (data['result'] == 'error') {
        this.validationErrors = data['response']['errors']
        this.helperService.setToastr('error', 'toastr.success.contact.title', 'toastr.error.fillAllData')
      }
      $("#mainContainer").removeClass('fullLoader')
    })
  }

}
