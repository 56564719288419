import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { HttpService } from './httpService';
import { UserService } from './userService';
import { Router } from '@angular/router';
import { CaptchaService } from './captcha.service';
import { PopUpService } from './pop-up.service';


declare var grecaptcha: any;
var popUpEvent;
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent {
  public textLanguage: string;
  public showPopUp: boolean = false;
  public cookiesAccepted: boolean;
  public popUpSettings: Object = {};
  constructor(private translate: TranslateService,
    private httpService: HttpService,
    public userService: UserService,
    private router: Router,
    private captchaService: CaptchaService,
    private popUpService: PopUpService) {
    translate.setDefaultLang('en');
    this.setLanguage('en')
    this.userService.checkToken('apiSnfKey')
    this.checkCookies()


    let reCaptchaInterval = setInterval(() => {
      if (typeof (grecaptcha) !== 'undefined' && grecaptcha.ready) {
        grecaptcha.ready(() => {
          this.captchaService.setCaptchaReady()
        });
        clearInterval(reCaptchaInterval)
      }
    }, 1000)



    popUpEvent = popUpService.action.subscribe((data) => {
      if (data['action'] == 'close') {
        this.showPopUp = false
        this.popUpSettings = {};
      }
      else if (data['settings']['type'] == 'specialOffer') {
        if (this.userService['loggedUser']['id'])
          this.checkSpecialOffer(data)
        else {
          this.showPopUp = true
          this.popUpSettings = data['settings']
        }
      }
      else if (data['settings']['type'] != 'specialOffer') {
        this.showPopUp = true
        this.popUpSettings = data['settings']
      }
    })
  }
  checkSpecialOffer(data) {
    if (this.userService['loggedUser']['used_coupon'] == 'no') {
      this.showPopUp = true
      this.popUpSettings = data['settings']
    }
    else this.popUpService.destroyPopUp()
  }
  checkCookies(): void {
    if (localStorage['cookiesAccepted'])
      this.cookiesAccepted = true
    else this.cookiesAccepted = false
  }
  setLanguage(lang: string): void {
    this.textLanguage = lang
    this.translate.use(lang);
    this.httpService.setPostApi(lang)
  }
  acceptCookies(): void {
    localStorage.setItem('cookiesAccepted', 'yes')
    this.checkCookies()
  }
  routerChange() {
    window.scrollTo(0, 0)
  }
}
