import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { HelperService } from '../helper-service.service';

declare var $: any
@Component({
  selector: 'app-buy-instant',
  templateUrl: './buy-instant.component.html',
  styleUrls: ['./buy-instant.component.css']
})
export class BuyInstantComponent implements OnInit {
  public instantSearch: Object = {};
  public validationErrors: Object = {};
  public validationRules = {
    person_email: ['required|email']
  }
  constructor(private helperService: HelperService,
    private router: Router) { }

  ngOnInit() {
  }
  sendInstantSearch(search: Object): void {
    this.validationErrors = {}
    var validated = true
    for (let key in this.validationRules) {
      this.validationErrors[key] = this.helperService.validatateRules(search[key], this.validationRules[key], search)
      if (this.validationErrors[key] && validated)
        validated = false
    }
    if (validated)
      this.router.navigate(['/clientPanel/results/' + search['person_email']])
  }

}
