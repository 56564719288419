import { Component, OnInit, Input } from '@angular/core';
import { HttpService } from '../httpService';
import { TableHeader } from '../models';
import { Router } from '@angular/router';
import { UserService } from '../userService';

declare var $: any;
@Component({
  selector: 'search-results',
  templateUrl: './search-results.component.html',
  styleUrls: ['./search-results.component.css']
})
export class SearchResultsComponent implements OnInit {
  public settings: Object = {};
  public auth: string;
  public results: Array<Object> = [];
  public dataQueryFinished: boolean;
  public showPopUp: boolean = false;
  public clickDisabled: boolean;
  public state: string = 'searching';
  public signUpSettings: Object = {}
  public searchHeaders: Array<TableHeader> = [
    { name: 'personName' },
    { name: 'gender' },
    { name: 'age' },
    { name: 'address' },
    { name: 'connections' },
    { name: 'otherInfo' },
    { name: 'action' }
  ]
  public didntMatchArray: Array<string> = [
    'fullName',
    'socialProfiles',
    'phones',
    'screenshots',
    'location',
    'compromised',
    'employment',
    'connections',
    'criminal',
    'websites'
  ]
  public noDataArray: Array<string> = [
    'personal',
    'dataCaptures',
    'contact',
    'criminal',
    'location',
    'compromised',
    'employment',
    'websites',
    'socialInfo',
    'connections'
  ]
  @Input() set searchData(object: Object) {
    if (object['email'] && object['place']) {
      this.settings = object
      this.setUp(this.settings)
    }
  }
  constructor(private httpService: HttpService,
    private router: Router,
    private userService: UserService) { }
  setSingUpType(type: string): void {
    this.signUpSettings = { type: type, fullForm: false }
  }
  toggleSignUpType(): void {
    if (this.signUpSettings['type'] == 'individual')
      this.setSingUpType('company')
    else if (this.signUpSettings['type'] == 'company')
      this.setSingUpType('individual')
  }
  setPopUp(value: boolean): void {
    this.showPopUp = value
    if (this.showPopUp)
      document.getElementsByTagName("body")[0].style.overflowY = 'hidden'
    else if (document.getElementsByTagName("body")[0].style.overflowY == 'hidden')
      document.getElementsByTagName("body")[0].style.overflowY = 'auto';
  }
  setDisable(value: boolean): void {
    this.clickDisabled = value
  }
  setUp(settings: Object): void {
    this.auth = ''
    if (this.userService.userToken)
      this.auth = 'authorization'
    this.getData(settings['email'])
  }
  handleSignUpEvent(eventData: Object): void {
    if (eventData['info'] == 'userLogged') {
      this.setPopUp(false)
      this.setUp(this.settings)
    }
    else if (eventData['info'] == 'verifyEmail')
      this.signUpSettings = { type: 'verify', verificationEmail: eventData['data']['email'] }
  }
  getData(email: string): void {
    $("#mainContainer").addClass('fullLoader')
    this.dataQueryFinished = false;
    this.state = 'searching'
    this.httpService.postObjectToUrl(this.httpService.postApi + 'v1/instant/search-email', { person_email: email }, 'json', this.auth).then(data => {
      if (data['result'] == 'success') {
        this.state = 'results'
        this.results = data['response']
      }
      else if (data['result'] == 'error' && data['response']['message']) {
        if (data['response']['message'] == 'login' || data['response']['message'] == 'limit_reached')
          this.state = data['response']['message']
        else this.state = 'results'
        if (this.state == 'login') {
          this.setSingUpType('individual')
          this.setPopUp(true)
        }
      }
      this.dataQueryFinished = true
      $("#mainContainer").removeClass('fullLoader')
    })
  }
  getReport(person: Object): void {
    $("#mainContainer").addClass('fullLoader')
    this.httpService.postObjectToUrl(this.httpService.postApi + 'v1/instant/request', { search_pointer: person['@search_pointer'], person_email: this.settings['email'] }, 'json', this.auth).then(data => {
      if (data['result'] == "success") {
        if (this.settings['place'] == 'public')
          this.router.navigate(['/landing/instantPreview/' + data['response']['id']])
        else if (this.settings['place'] == 'logged')
          this.router.navigate(['/clientPanel/viewInstant/' + data['response']['id']])
      }
      else if (data['result'] == "error" && data['response']['message']) {
        if (data['response']['message'] == 'limit_reached')
          this.state = data['response']['message']
      }
      $("#mainContainer").removeClass('fullLoader')
    })
  }
  ngOnInit() {
  }
  ngOnDestroy() {
    if (document.getElementsByTagName("body")[0].style.overflowY == 'hidden')
      document.getElementsByTagName("body")[0].style.overflowY = 'auto';
  }

}
