
import { Injectable, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { HelperService } from './helper-service.service';
import { httpPromiceData } from './models';
import { environment } from '../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';

declare var $: any;
@Injectable({
    providedIn: 'root'
})
export class UserService {
    public userToken: string = '';
    public storageKey: boolean = false;
    public userTaken: boolean = false;
    public loggedUser = {};
    public afterLogInRoute: string;
    public apiUrl: string = environment.apiUrl;
    public userEvent = new EventEmitter;
    constructor(private http: HttpClient,
        private router: Router,
        public helperService: HelperService) {
    }
    getUser() {
        var promise = new Promise((resolve, reject) => {
            try {
                var headers = new HttpHeaders().set('Authorization', 'Bearer ' + atob(localStorage[this.userToken]));
            }
            catch (e) { }
            this.http.get(this.apiUrl + 'auth/me', { headers: headers })
                .subscribe(
                    (data) => {
                        let responceData: httpPromiceData = { 'response': data, 'result': 'success' }
                        resolve(responceData)
                    },
                    (err) => {
                        this.logOut()
                        let responceData: httpPromiceData = { 'response': err, 'result': 'error' }
                        resolve(responceData)
                    })
        })
        return promise
    }
    checkToken(key: string) {
        if (localStorage[key]) {
            this.userToken = key
            this.storageKey = true
        }
        else this.storageKey = false
    }
    checkUserAuth() {

    }
    logOut() {
        $("#mainContainer").addClass('fullLoader')
        if (this.loggedUser['id']) {
            try {
                var headers = new HttpHeaders().set('Authorization', 'Bearer ' + atob(localStorage[this.userToken]));
            }
            catch (e) { }
            this.http.get(this.apiUrl + 'auth/logout', { headers: headers })
                .subscribe((data) => {
                    this.clearUserData()
                    $("#mainContainer").removeClass('fullLoader')
                },
                    (err) => {
                        if (err.status == 401 || err.status == 403) this.clearUserData()
                        if (err.status == 500) this.helperService.serverError()
                        $("#mainContainer").removeClass('fullLoader')
                    })
        }
        else this.clearUserData()
    }
    clearUserData() {
        localStorage.removeItem(this.userToken);
        this.loggedUser = {};
        this.storageKey = false
        if (this.router.url.includes('clientPanel'))
            this.router.navigateByUrl('/landing/plain')
        $("#mainContainer").removeClass('fullLoader')
    }
    setUser(object) {
        this.loggedUser = object
        if (!this.userTaken) {
            this.userTaken = true
            this.userEvent.emit('userSet')
        }
    }
    setAfterLogInRoute(route: string): void {
        this.afterLogInRoute = route
    }
}
