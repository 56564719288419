import { Component, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-dash-menu',
  templateUrl: './dash-menu.component.html',
  styleUrls: ['./dash-menu.component.css']
})
export class DashMenuComponent implements OnInit {
  centered = false;
  @Output() signout = new EventEmitter<void>();

  constructor() { }

  ngOnInit() {
  }

  signOut(): void {
    this.signout.emit();
  }

}
