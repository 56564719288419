import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpService } from '../httpService';
import { UserService } from '../userService';
import { PopUpService } from '../pop-up.service';

var routeParams;
declare var $: any;
@Component({
  selector: 'app-instant-preview',
  templateUrl: './instant-preview.component.html',
  styleUrls: ['./instant-preview.component.css']
})
export class InstantPreviewComponent implements OnInit, OnDestroy {
  public reportId: string;
  public reportData: Object = { available: {} };
  public instantPreview: Object = {};
  public reportTaken: boolean;
  public socialIcons: Array<Object> = [
    { name: 'facebook', icon: 'fab fa-facebook-f' },
    { name: 'flicr', icon: 'fab fa-flickr' },
    { name: 'youtube', icon: 'fab fa-youtube' },
    { name: 'google+', icon: 'fab fa-google-plus-g' },
    { name: 'skype', icon: 'fab fa-skype' },
    { name: 'instagram', icon: 'fab fa-instagram' },
    { name: 'linkedin', icon: 'fab fa-linkedin-in' },
    { name: 'dribbble', icon: 'fab fa-dribbble' },
    { name: 'twitter', icon: 'fab fa-twitter' },
    { name: 'pinterest', icon: 'fab fa-pinterest-p' },
  ]
  public reportInfos: Array<Object> = [
    { name: 'photos', icon: 'fas fa-camera' },
    { name: 'relationships', icon: 'fas fa-users' },
    { name: 'locations', icon: 'fas fa-map-marker-alt' },
    { name: 'employments', icon: 'fas fa-building' },
    { name: 'sexoffenders', icon: 'fas fa-ban' },
    { name: 'websites', icon: 'fas fa-globe-americas' },
    { name: 'phones', icon: 'fas fa-phone' },
    { name: 'compromised', icon: 'fas fa-question-circle' }
  ]
  public notFoundInfo: Array<string> = [
    "personal",
    "social",
    "contact",
    "dataCaptures",
    "location",
    "criminal",
    "employment",
    "connectionsMap"
  ]
  constructor(private activeRoute: ActivatedRoute,
    private httpService: HttpService,
    private userService: UserService,
    private router: Router,
    private popUpService: PopUpService) {
    routeParams = activeRoute.params.subscribe(data => {
      this.reportId = data.reportId
      this.getInstantData(this.httpService.postApi + 'v1/reports/' + this.reportId)
    })
  }
  getInstantData(url: string): void {
    $("#mainContainer").addClass('fullLoader')
    this.reportTaken = false;
    let authorization = ''
    if (this.userService.storageKey)
      authorization = 'authorization'
    this.httpService.getObjectFromUrl(url, authorization).then(data => {
      if (data['result'] == 'success') {
        this.reportData = data['response']['data']
        this.reportTaken = true
        // if (!this.reportData['available']['paid'])
        //   this.popUpService.initPopUp({ type: 'specialOffer', reportType: 'instant' })
      }
      $("#mainContainer").removeClass('fullLoader')
    })
  }
  unlockReport() {
    if (this.userService.storageKey)
      this.router.navigateByUrl('/clientPanel/checkout/instant/' + this.reportId + '/none')
    else {
      this.userService.setAfterLogInRoute('/clientPanel/checkout/instant/' + this.reportId + '/none')
      this.router.navigateByUrl('/landing/signUp/individual')
    }
  }
  ngOnInit() {
  }
  ngOnDestroy() {
    if (routeParams) routeParams.unsubscribe()
    this.popUpService.destroyPopUp()
  }

}
