import { Component, OnInit, Output, EventEmitter, NgZone, OnDestroy } from '@angular/core';
import { HttpService } from '../httpService';
import { HelperService } from '../helper-service.service';
import { CaptchaService } from '../captcha.service';

declare var $: any
declare var intlTelInput;
var captchaEvent;
@Component({
  selector: 'app-call-form-popup',
  templateUrl: './call-form-popup.component.html',
  styleUrls: ['./call-form-popup.component.css']
})
export class CallFormPopupComponent implements OnInit {
  public request: Object = { ampm: "am" };
  public validationErrors: Object = {};
  @Output() requestMade = new EventEmitter<string>();
  constructor(private httpService: HttpService,
    public helperService: HelperService,
    private captchaService: CaptchaService,
    private zone: NgZone) {
    if (this.captchaService.captchaReady)
      this.captchaService.getCaptchaCode()
    captchaEvent = this.captchaService.captchaEvent.subscribe(data => {
      this.zone.run(() => { this.request['captcha_token'] = data['captcha_token'] })
    })
  }

  ngOnInit() {
  }
  ngOnDestroy() {
    if (captchaEvent) captchaEvent.unsubscribe()
  }
  sendRequest(request: Object): void {
    $("#mainContainer").addClass('fullLoader')
    request['phone'] = $('#requestPhone').val()
    this.httpService.postObjectToUrl(this.httpService.postApi + 'v1/contact/phone', request, 'json', '').then(data => {
      this.validationErrors = {};
      this.request['captcha_token'] = ''
      this.captchaService.getCaptchaCode()
      if (data['result'] == 'success') {
        this.request = { ampm: "AM" };
        this.initPhone('requestPhone', '+1')
        this.helperService.setToastr('success', 'toastr.success.requestCall.title', 'toastr.success.requestCall.body')
        this.requestMade.emit('success')
      }
      else if (data['result'] == 'error') {
        this.validationErrors = data['response']['errors']
        this.helperService.setToastr('error', 'toastr.success.requestCall.title', 'toastr.error.fillAllData')
      }
      $("#mainContainer").removeClass('fullLoader')
    })
  }
  initPhone(id, value) {
    if (value)
      $('#' + id).val(value)
    intlTelInput(document.querySelector("#" + id), {
      autoHideDialCode: false,
      nationalMode: true,
    });
  }
  ngAfterViewInit() {
    this.initPhone('requestPhone', '+1')
  }

}
