import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { HttpService } from '../httpService';

var routeParams;
declare var $: any;
@Component({
  selector: 'app-buy-deep',
  templateUrl: './buy-deep.component.html',
  styleUrls: ['./buy-deep.component.css']
})
export class BuyDeepComponent implements OnInit {
  public prefilData: {};
  public reportTaken: boolean;
  public reportId: any;
  constructor(private activeRoute: ActivatedRoute,
    private httpService: HttpService) {
    routeParams = activeRoute.params.subscribe(data => {
      if (data.reportId != 'none') {
        this.reportId = data.reportId
        this.getReportData(this.httpService.postApi + 'v1/reports/' + this.reportId)
      }
      else {
        this.reportTaken = true
        this.prefilData = { prefil: false, data: {} }
      }
    })
  }
  getReportData(url: string): void {
    this.reportTaken = false
    $("#localSpiner").show()
    this.httpService.getObjectFromUrl(url, 'authorization').then(data => {
      if (data['result'] == 'success') {
        if (data['response']['data']['report'])
          this.prefilData = { prefil: true, data: data['response']['data']['report'] }
        else this.prefilData = { prefil: true, data: {} }
      }
      else this.prefilData = { prefil: false, data: {} }
      this.reportTaken = true
      $("#localSpiner").hide()
    })
  }
  ngOnInit() {
  }
  ngOnDestroy() {
    if (routeParams) routeParams.unsubscribe()
  }

}
