import { Component, OnInit, Input } from '@angular/core';


@Component({
  selector: 'thumbnail-img',
  template: '<img *ngIf="formatedSrc" [src]="formatedSrc">'
})
export class ThumbnailImg implements OnInit {
  public formatedSrc='';
  @Input() set srcData(object) {
    if (typeof object === "string") this.formatedSrc = object
    else {
      let reader = new FileReader();
      reader.onload = (e: any) => {
        this.formatedSrc = e.target.result;
      }
      reader.readAsDataURL(object);
    }
  }
  constructor() {
  }
  ngOnInit() {

  }
}
