import { Component, OnInit, OnDestroy } from '@angular/core';
import { PopUpService } from '../pop-up.service';

@Component({
  selector: 'app-deep-report',
  templateUrl: './deep-report.component.html',
  styleUrls: ['./deep-report.component.css']
})
export class DeepReportComponent implements OnInit, OnDestroy {
  public searchInfo: Array<Object> = [
    { name: 'manulyPrepared', image: '/assets/images/deep-icon-(1).png' },
    { name: 'visualPresentation', image: '/assets/images/deep-icon-(2).png' },
    { name: 'whiteLabel', image: '/assets/images/deep-icon-(4).png' },
    { name: 'worlwide', image: '/assets/images/deep-icon-(3).png' }
  ]
  constructor(public popUpService: PopUpService) { }
  ngOnInit() {
  }
  ngOnDestroy() {
    this.popUpService.closePopUp()
  }
}
