import { Injectable, EventEmitter } from '@angular/core';

declare var grecaptcha: any;
@Injectable({
  providedIn: 'root'
})
export class CaptchaService {
  public captchaReady: boolean;
  public captchaEvent = new EventEmitter;
  constructor() { }
  setCaptchaReady() {
    this.captchaReady = true;
    this.getCaptchaCode()
  }
  getCaptchaCode() {
    grecaptcha.execute('6Lc9S5gUAAAAAEfIY4cvWTz9Rsi-DlXXUNy59-EY', {}).then((token) => {
      this.captchaEvent.emit({ captcha_token: token });
    })
  }
}