import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'formatPrice'
})
export class FormatPricePipe implements PipeTransform {

  transform(number: any, args?: any): any {
    let value = number.toString()
    if (value.includes('.')) {
      let components = value.split('.')
      return '$ ' + components[0] + '.' + '<small>' + components[1] + '</small>'
    }
    else return '$ ' + value
  }

}
