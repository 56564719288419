import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from '../userService';

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.css']
})
export class AboutComponent implements OnInit {
  public aboutElements:Array<Object>=[
    {name:"saveTime",img:"/assets/images/save-time.png"},
    {name:"saveMoney",img:"/assets/images/save-money.png"},
    {name:"bestResults",img:"/assets/images/get-best-results.png"}
  ]
  constructor(public userService: UserService, private router: Router) {
    if (userService.storageKey) {
      // $("#mainContainer").addClass('fullLoader')
      userService.getUser().then(data => {
        if (data['result'] == 'success')
          userService.setUser(data['response'])
        // $("#mainContainer").removeClass('fullLoader')
      })
    }
    // else this.router.navigateByUrl('/landing/home')
  }
  
  ngOnInit() {
  }

}
