import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { PopUpService } from '../pop-up.service';
import { HttpService } from '../httpService';
import { UserService } from '../userService';

declare var $: any;
var captchaEvent;
@Component({
  selector: 'main-pop-up',
  templateUrl: './main-pop-up.component.html',
  styleUrls: ['./main-pop-up.component.css']
})
export class MainPopUpComponent implements OnInit, OnDestroy {
  public popUpType: string;
  public clickDisabled: boolean;
  public popUpSettings: Object = {};
  public validationErrors: Object = {};
  public couponTaken: boolean = false;
  @Input() set settings(settings: Object) {
    this.setType(settings['type'])
    this.popUpSettings = settings
  }
  constructor(private popUpService: PopUpService,
    private httpService: HttpService,
    private userService: UserService) { }
  setType(type: string): void {
    this.popUpType = type
  }
  getCoupon(couponData) {
    $("#mainContainer").addClass('fullLoader')
    let auth = ''
    if (this.userService.storageKey)
      auth = 'authorization'
    this.httpService.postObjectToUrl(this.httpService.postApi + 'v1/coupon/send-promo', couponData, 'json', auth).then(data => {
      this.validationErrors = {}
      if (data['result'] == 'success') {
        this.couponTaken = true;
        this.userService.loggedUser['used_coupon'] = 'yes'
      }
      else if (data['result'] == 'error') {
        if (data['response']['errors'])
          this.validationErrors = data['response']['errors']
        else if (data['response']['message'])
          this.validationErrors['errorMessage'] = data['response']['message']
      }
      $("#mainContainer").removeClass('fullLoader')
    })
  }
  setDisable(value: boolean): void {
    this.clickDisabled = value
  }
  handleEvent(event, popUp) {
    if (popUp == 'exampleReport') {
      if (event == 'success')
        this.closePopUp()
    }
  }
  closePopUp() {
    this.popUpService.destroyPopUp()
  }
  ngOnInit() {
    document.getElementsByTagName("body")[0].style.overflowY = 'hidden'
  }
  ngOnDestroy() {
    if (document.getElementsByTagName("body")[0].style.overflowY == 'hidden')
      document.getElementsByTagName("body")[0].style.overflowY = 'auto';
    if (captchaEvent) captchaEvent.unsubscribe()
  }

}
